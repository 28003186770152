import _ from 'lodash';
import { env, dev, qat, prod, stage } from './config';
import { message } from 'antd';
import { isEmpty, isObject } from 'lodash';
import dayjs from 'dayjs';

export const handleError = (error) => {
  let message = 'Something went wrong';
  if (error.response) {
    if (error.response?.data && error.response?.data?.errors) {
      message = error.response?.data?.errors[0]?.message;
    } else if (error?.response?.data?.message) {
      message = message.error(error?.response?.data?.message);
    }
  } else {
    message = message.error(error?.message);
  }

  return message;
};

export const errorhandling = (error) => {
  if (error.response) {
    if (error.response?.data && error.response?.data?.errors) {
      return message.error(error.response?.data?.errors[0]?.message || 'Something went wrong');
    } else if (error?.response?.data?.message) {
      return message.error(error?.response?.data?.message);
    }
  } else {
    return message.error(error?.message);
  }
};

export const formatNumber = (number) => {
  return number.toLocaleString('en-US', {
    style: 'currency',
    currency: 'GBP',
  });
};

export const formatDate = (date, format) => {
  if (!date) return '-';
  if (!dayjs(date).isValid()) return 'Invalid Date';
  return dayjs(date).format(format || 'DD/MM/YYYY HH:mm');
};

export const oneMonthPriorDate = () => {
  let dt = new Date();
  dt.setMonth(dt.getMonth() - 1);
  return dt;
};

export const getBaseURL = () => {
  if (env === 'dev') {
    return dev.baseURL;
  }
  if (env === 'prod') {
    return prod.baseURL;
  }
  if (env === 'stage') {
    return stage.baseURL;
  }
  return qat.baseURL;
};

export const getAdminKey = () => {
  if (env === 'dev') {
    return dev.ADMIN_KEY;
  }
  if (env === 'prod') {
    return prod.ADMIN_KEY;
  }
  if (env === 'stage') {
    return stage.ADMIN_KEY;
  }
  return qat.ADMIN_KEY;
};

export const getS3BucketURL = () => {
  if (env === 'dev') {
    return dev.s3BucketPath;
  }
  if (env === 'prod') {
    return prod.s3BucketPath;
  }
  if (env === 'stage') {
    return stage.s3BucketPath;
  }
  return qat.s3BucketPath;
};

export const getS3PayrollBucket = () => {
  if (env === 'dev') {
    return dev.s3PayrollBucket;
  }
  if (env === 'prod') {
    return prod.s3PayrollBucket;
  }
  if (env === 'stage') {
    return stage.s3PayrollBucket;
  }
  return qat.s3PayrollBucket;
};

export const processEmployerDetails = ({
  // new
  payrollConfig,
  accessFeeConfigs,
  accessLimits,
  companyDetails,
  contactPersonDetails,
  directDebitAccountConfig,
  subscription,
  fundingConfig,
  payrollAuthConfig,
  feeAndLimitConfig,
}) => {
  let object = {};

  if (!isEmpty(companyDetails)) {
    object.companyDetails = {
      ...companyDetails,
      // contractEndDate: companyDetails.contractEndDate && companyDetails.contractEndDate !== "" ? formatDate(companyDetails.contractEndDate, 'YYYY-MM-DDTHH:mm:ss') : "",
      // legalDocs: isObject(companyDetails.legalDocs) ? ((companyDetails.legalDocs || {}).fileList || []).map(item => item.name).join(",") : companyDetails.legalDocs
    };
  }

  if (!isEmpty(payrollConfig)) {
    object.payrollConfig = {
      ...payrollConfig,
    };
  }

  if (!isEmpty(contactPersonDetails)) {
    object.contactPerson = {
      ...contactPersonDetails,
    };
  }
  if (!isEmpty(feeAndLimitConfig)) {
    // Override feeCalculationType
    if (feeAndLimitConfig.accessFeeConfigs) {
      feeAndLimitConfig.accessFeeConfigs.feeCalculationType = 'AMOUNT';
    }

    object.feeAndLimitConfig = {
      ...feeAndLimitConfig,
    };
  }

  if (!isEmpty(accessFeeConfigs)) {
    if (accessFeeConfigs.merchantSurcharge) {
      accessFeeConfigs.merchantSurcharge.feeType = 'MCHCHRGF';
      accessFeeConfigs.merchantSurcharge.feeCalculationType = 'PERCENTAGE';
    }
    if (accessFeeConfigs.merchantSurchargeSavings) {
      accessFeeConfigs.merchantSurchargeSavings.feeType = 'MCHCHRGFAS';
      accessFeeConfigs.merchantSurchargeSavings.feeCalculationType = 'PERCENTAGE';
    }
    if (accessFeeConfigs.withdrawalFee) {
      accessFeeConfigs.withdrawalFee.feeType = 'WDF';
      accessFeeConfigs.withdrawalFee.feeCalculationType = 'AMOUNT';
    }
    if (accessFeeConfigs.withdrawalFeeSavings) {
      accessFeeConfigs.withdrawalFeeSavings.feeType = 'WDFAS';
      accessFeeConfigs.withdrawalFeeSavings.feeCalculationType = 'AMOUNT';
    }
    object.accessFeeConfigs = accessFeeConfigs;
  }

  if (!isEmpty(accessLimits)) {
    object.accessLimits = {
      ...accessLimits,
      policyName: 'DEFAULT',
      policyType: 'DEFAULT',
      maxWithdrawalPerMonth: 2,
      maxPayperiodLimitSalaried: 2,
    };
  }

  if (!isEmpty(fundingConfig)) {
    object.fundingConfig = fundingConfig;
  }

  if (!isEmpty(payrollAuthConfig)) {
    object.payrollAuthConfig = payrollAuthConfig;
  }

  if (!isEmpty(directDebitAccountConfig)) {
    object.directDebitAccountConfig = setDirectDebitAccountConfig(directDebitAccountConfig);
  }

  if (!isEmpty(subscription)) {
    object.subscription = {
      ...subscription,
      employerRate: subscription.employerRate || 0,
      employeeRate: subscription.employeeRate || 0,
    };
  }
  return {
    employerDetails: object,
  };
};

export const setDirectDebitAccountConfig = (directDebitAccountConfig) => {
  return {
    ...directDebitAccountConfig,
    payrollBankDDAuthDocs: isObject(directDebitAccountConfig.payrollBankDDAuthDocs)
      ? ((directDebitAccountConfig.payrollBankDDAuthDocs || {}).fileList || [])
          .map((item) => item.name)
          .join(',')
      : directDebitAccountConfig.payrollBankDDAuthDocs,
    saasBankDDAuthDocs: isObject(directDebitAccountConfig.saasBankDDAuthDocs)
      ? ((directDebitAccountConfig.saasBankDDAuthDocs || {}).fileList || [])
          .map((item) => item.name)
          .join(',')
      : directDebitAccountConfig.saasBankDDAuthDocs,
  };
};

export const disabledFutureDates = (current) => {
  return current > dayjs().endOf('day');
};

export const disabledTodayAndPreviousDates = (current) => {
  return current <= dayjs().endOf('day');
};

export const disabledPreviousDates = (current) => {
  return current < dayjs().subtract(1, 'day').endOf('day');
};

export const disabledStartdates = (current, end) => {
  return current < dayjs().subtract(1, 'day').endOf('day');
};

export const disabledEnddates = (current, start) => {
  if (start) {
    return current < dayjs(start).add(1, 'day');
  }
  return current < dayjs().subtract(1, 'day').endOf('day');
};

export const minValueValidator = (value, min, max) => {
  return (value || value == 0) && parseFloat(value) > min
    ? Promise.resolve()
    : Promise.reject('Please input greated than 0!');
};

export const minAndMaxValueValidator = (value, min, max) => {
  return (value || value == 0) && parseFloat(value) >= min && parseFloat(value) <= max
    ? Promise.resolve()
    : Promise.reject('Please input between 0 to 100!');
};

export const validatePassword = (value) => {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9])\S{8,99}$/.test(value);
};

export const getFileType = (file) => {
  return new Promise((resolve) => {
    const fr = new FileReader();
    const blob = file.slice(0, 4);
    fr.readAsArrayBuffer(blob);
    fr.onloadend = (evt) => {
      if (evt.target.readyState === FileReader.DONE) {
        const uint = new Uint8Array(evt.target.result);
        let bytes = [];
        uint.forEach((byte) => {
          bytes.push(byte.toString(16));
        });
        const hex = bytes.join('').toUpperCase();
        const fileType = getMimetype(hex);
        resolve(fileType);
      }
    };
  });
};

const getMimetype = (signature) => {
  switch (signature) {
    case 'D0CF11E0':
      return 'application/msword';
    case '504B34':
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    case '25504446':
      return 'application/pdf';
    default:
      return '';
  }
};

export const validateAlphabetOnly = (value) => {
  return /^[a-zA-Z ]*$/.test(value);
};

export const ValidateNumberOnly = (value) => {
  return /^\d+(\.\d{1,2})?$/.test(value);
};

export const validateWebsiteOnly = (value) => {
  return /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/.test(
    value
  );
};

export function toCurrency(number = 0, currency = 'GBP', withSymbol = true) {
  return withSymbol
    ? number?.toLocaleString('en-US', {
        style: 'currency',
        currency: currency,
      })
    : number?.toLocaleString('en-US', {
        style: 'decimal',
        useGrouping: true,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
}

export function removeKeys(obj, keys) {
  const clone = { ...obj };
  keys.forEach((key) => delete clone[key]);
  return clone;
}

// a function that will chack array of objects and remove items.name from the array of objects
export function removeKeysFromArray(array, keys) {
  return array.filter((item) => !keys.includes(item.name));
}

// a function that will take key and value if key is firstName or lastName then make first letter capital using _.capitalize
export function capitalizeFirstLetter(key, value) {
  if (key === 'firstName' || key === 'lastName') {
    return _.capitalize(value);
  }
  return value;
}

export const FileTypes = {
  EARNINGS: 'EARNINGS',
  DAILY_SHIFT_EARNINGS: 'DAILY_SHIFT_EARNINGS',
  EMPLOYEE_DETAILS: 'EMPLOYEE_DETAILS',
};

export const formatFileType = (fileType) => {
  switch (fileType) {
    case 'EarningsFile':
      return FileTypes.EARNINGS;
    case 'DailyShiftEarningsFile':
      return FileTypes.DAILY_SHIFT_EARNINGS;
    case 'EmployeeDetailsFile':
      return FileTypes.EMPLOYEE_DETAILS;
    default:
      return FileTypes.EARNINGS;
  }
};
/**
 * Checks if a date is before another date.
 * @param {string} date1 - The first date in YYYY-MM-DD format.
 * @param {string} date2 - The second date in YYYY-MM-DD format.
 * @returns {boolean} - True if date1 is before date2, otherwise false.
 */
export const isDateBefore = (date1, date2) => {
  if (!date1 || !date2) return false;
  return dayjs(date1).isBefore(dayjs(date2));
};

/**
 * Checks if a date is after another date.
 * @param {string} date1 - The first date in YYYY-MM-DD format.
 * @param {string} date2 - The second date in YYYY-MM-DD format.
 * @returns {boolean} - True if date1 is after date2, otherwise false.
 */
export const isDateAfter = (date1, date2) => {
  if (!date1 || !date2) return false;
  return dayjs(date1).isAfter(dayjs(date2));
};

export const convertToCSV = (data = [], headers = [], delimiter = ',') => {
  if (!data?.length && !headers?.length) return '';
  const csvRows = [];

  // header row
  const headerLabels = headers.map(({ label }) => `${label}`);
  csvRows.push(headerLabels.join(delimiter));

  // data rows
  data.forEach((item) => {
    const values = headers.map(({ key }) => {
      const value = item[key];
      return value?.toString() || '';
    });
    csvRows.push(values.join(delimiter));
  });
  return csvRows.join('\n');
};

export const downloadFile = (content, contentType, fileName) => {
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const exportAsCSV = ({
  list = [],
  headers = [],
  config: { delimiter = ',', fileName, onStart, onSuccess, onFailure },
}) => {
  try {
    onStart && onStart();
    const csvContent = convertToCSV(list, headers, delimiter);
    const filename = fileName
      ? `${fileName}.csv`
      : `data-${new Date().toISOString().split('T')[0]}.csv`;
    downloadFile(csvContent, 'text/csv;charset=utf-8;', filename);
    onSuccess && onSuccess();
  } catch (error) {
    console.error('Error exporting CSV:', error);
    onFailure && onFailure(error);
  }
};
