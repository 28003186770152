import React from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import dayjs from 'dayjs';
import { HiOutlineDocumentCheck } from 'react-icons/hi2';
import { FileTypes } from '../../shared/components/FileModelCsvUpload/constants';
import { DeletePopconfirm } from '../../shared';

export const fileTypeOptions = [
  { value: FileTypes.EARNINGS, label: 'Employee Earnings' },
  { value: FileTypes.DAILY_SHIFT_EARNINGS, label: 'Daily Shift Earnings' },
  { value: FileTypes.EMPLOYEE_DETAILS, label: 'Employee Details' },
];

export const fileTypeLabels = fileTypeOptions.reduce(
  (acc, { value, label }) => ({ ...acc, [value]: label }),
  {}
);

export const listColumns = ({ onPreview, onDelete, onEdit }) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: '30%',
  },
  {
    title: 'File Type',
    dataIndex: 'fileType',
    key: 'fileType',
    render: (fileType) => fileTypeLabels[fileType],
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (date) => dayjs(date).format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    title: 'Updated At',
    dataIndex: 'updated_at',
    key: 'updated_at',
    render: (date) => dayjs(date).format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    title: 'Actions',
    key: 'actions',
    align: 'center',
    width: '20rem',
    render: (_, record) => (
      <Space size="middle">
        <Button icon={<HiOutlineDocumentCheck />} onClick={() => onPreview && onPreview(record)}>
          Preview
        </Button>
        <Button type="primary" icon={<EditOutlined />} onClick={() => onEdit && onEdit(record)}>
          Edit
        </Button>
        <DeletePopconfirm
          buttonText="Delete"
          description="Are you sure you want to delete this file model?"
          title="Delete File Model"
          onDelete={() => onDelete && onDelete(record)}
        />
      </Space>
    ),
  },
];
