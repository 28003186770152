import React from 'react';
import PropTypes from 'prop-types';
import { Select, Skeleton } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

/**
 * A Select component with search functionality.
 *
 * @param {any} value The currently selected value.
 * @param {function} onChange Called when the user selects a new value.
 * @param {boolean} [allowClear=true] Whether to show a clear button.
 * @param {Array} [options=[]] The options to be rendered in the dropdown.
 * @param {boolean} [loading=false] Whether the Select is in a loading state.
 * @param {boolean} [showSearch=true] Whether to show a search bar.
 * @param {Array<string>} [filterFields=['label']] The fields to filter by.
 * @param {string} [placeholder=''] Text to show in the input when no value is selected.
 * @param {ReactNode} [dropDownHeader=null] An element to show at the top of the dropdown.
 * @param {ReactNode} [dropDownFooter=null] An element to show at the bottom of the dropdown.
 */
export default function SelectWithSearch({
  value,
  onChange,
  allowClear = true,
  options = [],
  loading = false,
  showSearch = true,
  filterFields = ['label'], // OR ['label', 'value']
  placeholder = '',
  dropDownHeader = null,
  dropDownFooter = null,
  ...props
}) {
  const filterOption = (input, option) =>
    filterFields.some((field) =>
      (option?.[field] ?? '').toLowerCase().includes(input.toLowerCase())
    );

  const ClearIcon = () => (
    <CloseCircleOutlined style={{ fontSize: '1.2rem', marginTop: '-5px', marginLeft: '-5px' }} />
  );

  if (loading) return <Skeleton.Input active size={'large'} block />;

  return (
    <Select
      allowClear={allowClear ? { clearIcon: <ClearIcon /> } : allowClear}
      loading={loading}
      showSearch={showSearch}
      filterOption={filterOption}
      options={options}
      placeholder={placeholder}
      value={value}
      onChange={(value, option) => onChange && onChange(value, option)}
      size="large"
      dropdownRender={(menu) => (
        <>
          {dropDownHeader}
          {menu}
          {dropDownFooter}
        </>
      )}
      {...props}
    />
  );
}

SelectWithSearch.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowClear: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      value: PropTypes.any.isRequired,
    })
  ),
  loading: PropTypes.bool,
  showSearch: PropTypes.bool,
  filterFields: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string,
  dropDownHeader: PropTypes.node,
  dropDownFooter: PropTypes.node,
};

// Default Props
SelectWithSearch.defaultProps = {
  allowClear: true,
  options: [],
  loading: false,
  showSearch: true,
  filterFields: ['label'],
  placeholder: '',
  dropDownHeader: null,
  dropDownFooter: null,
};
