import { Card, Table } from 'antd';
import React, { memo, useMemo } from 'react';

const PreviewTable = memo(({ data, columns, title = '', maxHeight = '20rem', loading, extra }) => {
  const { previewColumns, previewRows } = useMemo(() => {
    if (!data) return { previewColumns: [], previewRows: [] };

    const previewColumns = columns.map((field) => ({
      title: field,
      dataIndex: field,
      key: field,
      align: 'center',
      elipsis: true,
    }));

    const previewRows = data.map((row, rowIndex) => ({ ...row, rowIndex }));

    return { previewColumns, previewRows };
  }, [columns, data]);

  if (!previewColumns.length) return null;

  return (
    <Card title={title} type="inner" size="small" loading={loading} extra={extra}>
      <Table
        loading={loading}
        columns={previewColumns}
        dataSource={previewRows}
        rowKey={'rowIndex'}
        scroll={{ x: 'max-content' }}
        tableLayout="fixed"
        size="small"
        pagination={{
          position: ['bottomCenter'],
          align: 'center',
          size: 'default',
          showTotal: (total) => `Total ${total} items`,
        }}
      />
    </Card>
  );
});

export default PreviewTable;
