import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { ExportOutlined } from '@ant-design/icons';

function ExportToCSV({ label = 'Export to CSV', disabled, loading, onClick, ...props }) {
  return (
    <Button
      type="primary"
      color="primary"
      style={{ backgroundImage: 'linear-gradient(to bottom, #ffffff16 0%, #00000016 100%)' }}
      icon={<ExportOutlined />}
      loading={loading}
      disabled={disabled}
      {...props}
      onClick={onClick}>
      {loading ? 'Exporting...' : label}
    </Button>
  );
}

ExportToCSV.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  label: PropTypes.node,
  onClick: PropTypes.func,
};

ExportToCSV.defaultProps = {
  disabled: false,
  loading: false,
  label: 'Export to CSV',
};
export default ExportToCSV;
