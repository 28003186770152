import React, { Fragment, memo } from 'react';
import {
  Form,
  Input,
  InputNumber,
  Switch,
  Row,
  Col,
  Collapse,
  Select,
  Button,
  Drawer,
  Flex,
  Typography,
} from 'antd';
import { fileTypeOptions } from '../constants';
import { PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { createFileModel, updateFileModel } from '../actions';
import { getColumnType } from '../../../shared/components/FileModelCsvUpload/constants';
import DateFormatInput from '../../../shared/components/DateFormatInput';

export default function UpsertModalForm({
  fileModelsConfig,
  isOpen,
  closeDrawer,
  employerId,
  updatingFileModel,
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { actionLoading } = useSelector((state) => state.fileModels.list);
  const [selectedFileType, setSelectedFileType] = React.useState(null);

  const isUpdating = React.useMemo(() => Boolean(updatingFileModel), [updatingFileModel]);

  React.useEffect(() => {
    if (isUpdating) {
      // set the form values
      setSelectedFileType(updatingFileModel.fileType);

      const columns = {};
      updatingFileModel.columns.forEach((col) => {
        columns[col.targetField] = { name: col.inputColumn, format: col.inputFormat || '' };
      });

      form.setFieldsValue({
        columns,
        fileType: updatingFileModel.fileType,
        name: updatingFileModel.name,
        processingConfig: {
          ...updatingFileModel.processingConfig,
          fuzzyMatchingDistance: updatingFileModel.columns[0].fuzzyMatchDistance,
        },
      });
    }
  }, [updatingFileModel, isUpdating]);

  const onFileTypeChange = (fileType) => {
    setSelectedFileType(fileType);
    form.resetFields(['columns']);
    form.setFieldsValue({ fileType });
  };

  const onClose = () => {
    form.resetFields();
    setSelectedFileType(null);
    closeDrawer();
  };

  const onSubmit = (values) => {
    const { name, columns, processingConfig } = values;
    // transform the data to the expected format
    const transformedColumns = Object.keys(columns).map((key) => ({
      targetField: key,
      inputColumn: columns[key].name,
      inputFormat: columns[key].format || '',
      fuzzyMatchDistance: processingConfig.fuzzyMatchingDistance,
    }));
    const transformedData = {
      fileType: selectedFileType,
      name,
      processingConfig: {
        delimiter: processingConfig.delimiter,
        skipRows: processingConfig.skipRows,
        hasHeaderRow: processingConfig.hasHeaderRow,
        skipInvalidRows: processingConfig.skipInvalidRows,
      },
      columns: transformedColumns,
    };

    if (isUpdating) {
      dispatch(
        updateFileModel({
          employerId,
          fileModelId: updatingFileModel.id,
          fileModel: transformedData,
          onSuccess: onClose,
        })
      );
    } else {
      dispatch(createFileModel({ ...transformedData, employerId, onSuccess: onClose }));
    }
  };

  return (
    <Drawer
      title={isUpdating ? 'Update File Model' : 'New File Model'}
      maskClosable={false}
      open={isOpen}
      onClose={onClose}
      destroyOnClose
      loading={actionLoading}
      extra={
        <Flex justify={'end'} align="center" gap={'large'}>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            type="primary"
            htmlType="submit"
            icon={<SaveOutlined />}
            loading={actionLoading}
            disabled={!selectedFileType}>
            Save
          </Button>
        </Flex>
      }
      width={'50rem'}
      styles={{ content: { height: '100vh', overflow: 'auto' } }}
      // wrapping the drawer content in a form
      drawerRender={(dom) => (
        <Form
          layout="horizontal"
          form={form}
          clearOnDestroy
          onFinish={onSubmit}
          children={dom}
          labelWrap
        />
      )}>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Form.Item
            name="fileType"
            label="File Type"
            rules={[{ required: true }]}
            initialValue={selectedFileType}
            labelAlign="left"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            placeholder="Select a file type">
            <Select
              placeholder="Select file type"
              size="large"
              options={fileTypeOptions}
              disabled={isUpdating}
              onChange={onFileTypeChange}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            name="name"
            label="Model Name"
            rules={[{ required: true }]}
            labelCol={{ span: 10 }}
            labelAlign="left"
            wrapperCol={{ span: 14 }}>
            <Input placeholder="Enter model name" size="large" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <ColumnMappingInputs fileType={selectedFileType} fileModelsConfig={fileModelsConfig} />
        </Col>

        <Col span={24}>
          <ProcessingConfigInputs fileType={selectedFileType} fileModelsConfig={fileModelsConfig} />
        </Col>
      </Row>
    </Drawer>
  );
}

export const NewFileModelButton = ({ onClick, disabled }) => {
  return (
    <Button type="primary" icon={<PlusOutlined />} disabled={disabled} onClick={onClick}>
      New File Model
    </Button>
  );
};

const ColumnMappingInputs = memo(function ({ fileType, fileModelsConfig }) {
  const columns = fileModelsConfig?.[fileType];
  if (!columns) return null;

  const hasFormatField = (col) => col.type === 'Date';

  // move the ones that have a format field to the bottom
  const FormItems = columns
    ?.sort((a, b) => hasFormatField(a) - hasFormatField(b))
    ?.map((col) => {
      const commonProps = {
        name: ['columns', col.name, 'name'],
        label: `${col.label} (type: ${getColumnType(col.type)})`,
        rules: [{ required: col.required }],
        labelAlign: 'left',
      };

      return hasFormatField(col) ? (
        <Fragment key={col.name}>
          <Col span={14}>
            <Form.Item {...commonProps} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
              <Input placeholder={`Enter ${col.label} Column Name`} />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name={['columns', col.name, 'format']} initialValue={col.format}>
              <DateFormatInput />
            </Form.Item>
          </Col>
        </Fragment>
      ) : (
        <Col span={24} key={col.name}>
          <Form.Item {...commonProps} labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}>
            <Input placeholder={`Enter ${col.label} Column Name`} />
          </Form.Item>
        </Col>
      );
    });

  return (
    <Collapse
      bordered={true}
      size="small"
      defaultActiveKey={['column-mapping']}
      destroyInactivePanel={false}
      items={[
        {
          key: 'column-mapping',
          label: 'Column Mapping',
          forceRender: true,
          extra: (
            <Typography.Text type="secondary">
              match the specified fields with your file's columns
            </Typography.Text>
          ),
          children: <Row gutter={[16, 8]}>{FormItems}</Row>,
        },
      ]}
    />
  );
});

const ProcessingConfigInputs = memo(function () {
  const items = [
    {
      name: 'delimiter',
      label: 'Delimiter',
      initialValue: ',',
      tooltip: 'Character used to separate fields',
      component: <Input />,
    },
    {
      name: 'skipInvalidRows',
      label: 'Skip Invalid Rows',
      initialValue: true,
      valuePropName: 'checked',
      tooltip: 'Whether to skip rows with invalid data',
      component: <Switch />,
    },
    {
      name: 'skipRows',
      label: 'Skip Rows',
      initialValue: 1,
      tooltip: 'Number of rows to skip at the beginning',
      component: <InputNumber min={0} style={{ width: '100%' }} />,
    },
    {
      name: 'hasHeaderRow',
      label: 'Has Header Row',
      initialValue: true,
      valuePropName: 'checked',
      tooltip: 'Whether the input file has a header row',
      component: <Switch />,
    },
    {
      name: 'fuzzyMatchingDistance',
      label: 'Fuzzy Matching Distance',
      initialValue: 2,
      tooltip: 'Max character difference for fuzzy match',
      component: <InputNumber min={0} style={{ width: '100%' }} />,
    },
  ];

  return (
    <Collapse
      bordered={true}
      size="small"
      destroyInactivePanel={false}
      items={[
        {
          key: 'processing-config',
          label: 'Processing Config',
          forceRender: true,
          children: (
            <Row gutter={[16, 8]}>
              {items.map(({ name, label, initialValue, tooltip, valuePropName, component }) => (
                <Col span={12} key={name}>
                  <Form.Item
                    name={['processingConfig', name]}
                    label={label}
                    initialValue={initialValue}
                    labelAlign="left"
                    labelCol={{ span: 14 }}
                    wrapperCol={{ span: 10 }}
                    required
                    tooltip={tooltip}
                    {...(valuePropName ? { valuePropName } : {})}>
                    {component}
                  </Form.Item>
                </Col>
              ))}
            </Row>
          ),
        },
      ]}
    />
  );
});
