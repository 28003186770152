export const GET_FILE_MODELS_CONFIG = 'GET_FILE_MODELS_CONFIG';
export const GET_FILE_MODELS_CONFIG_LOADING = 'GET_FILE_MODELS_CONFIG_LOADING';
export const GET_FILE_MODELS_CONFIG_SUCCESS = 'GET_FILE_MODELS_CONFIG_SUCCESS';
export const GET_FILE_MODELS_CONFIG_ERROR = 'GET_FILE_MODELS_CONFIG_ERROR';

export const GET_FILE_MODELS = 'GET_FILE_MODELS';
export const GET_FILE_MODELS_LOADING = 'GET_FILE_MODELS_LOADING';
export const GET_FILE_MODELS_SUCCESS = 'GET_FILE_MODELS_SUCCESS';
export const GET_FILE_MODELS_ERROR = 'GET_FILE_MODELS_ERROR';

export const CREATE_FILE_MODEL = 'CREATE_FILE_MODEL';
export const CREATE_FILE_MODEL_LOADING = 'CREATE_FILE_MODEL_LOADING';
export const CREATE_FILE_MODEL_SUCCESS = 'CREATE_FILE_MODEL_SUCCESS';
export const CREATE_FILE_MODEL_ERROR = 'CREATE_FILE_MODEL_ERROR';

export const DELETE_FILE_MODEL = 'DELETE_FILE_MODEL';
export const DELETE_FILE_MODEL_LOADING = 'DELETE_FILE_MODEL_LOADING';
export const DELETE_FILE_MODEL_SUCCESS = 'DELETE_FILE_MODEL_SUCCESS';
export const DELETE_FILE_MODEL_ERROR = 'DELETE_FILE_MODEL_ERROR';

export const UPDATE_FILE_MODEL = 'UPDATE_FILE_MODEL';
export const UPDATE_FILE_MODEL_LOADING = 'UPDATE_FILE_MODEL_LOADING';
export const UPDATE_FILE_MODEL_SUCCESS = 'UPDATE_FILE_MODEL_SUCCESS';
export const UPDATE_FILE_MODEL_ERROR = 'UPDATE_FILE_MODEL_ERROR';

export const getFileModelsConfig = () => ({ type: GET_FILE_MODELS_CONFIG });
export const getFileModels = (body) => ({ type: GET_FILE_MODELS, body });
export const createFileModel = (body) => ({ type: CREATE_FILE_MODEL, body });
export const deleteFileModel = (body) => ({ type: DELETE_FILE_MODEL, body });
export const updateFileModel = (body) => ({ type: UPDATE_FILE_MODEL, body });
