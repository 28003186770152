import React, { useState } from 'react';
import { usePreviewContext } from './context';
import { Alert, Typography } from 'antd';

export default function ErrorsList() {
  const { errors } = usePreviewContext();
  const [descriptionExpanded, setDescriptionExpanded] = useState(false);

  if (errors.list.length === 0) return null;

  return errors.list.map((error, i) => {
    let description = error?.message || 'Something went wrong';
    if (error?.rows) {
      description += `, on rows: ${error.rows.join(', ')}`;
    }
    return (
      <Alert
        key={i}
        message={`Error: ${error?.code}`}
        description={
          <Typography.Paragraph
            ellipsis={{
              rows: 2,
              expandable: 'collapsible',
              expanded: descriptionExpanded,
              onExpand: (_, info) => setDescriptionExpanded(info.expanded),
            }}>
            {description}
          </Typography.Paragraph>
        }
        type="error"
        showIcon
      />
    );
  });
}
