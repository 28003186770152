import React from 'react';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  EditOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { App, Button, Card, Empty, Image, List, Tag, Tooltip, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import { deleteArticle } from '../actions';
import { useArticlesContext } from '../containers/ArticlesContext';
import { DeletePopconfirm } from '../../../shared';
import { DRAWER_ACTIONS } from '../constants';

function ArticlesList({ articles, loading }) {
  const dispatch = useDispatch();
  const { modal } = App.useApp();
  const { openDrawer } = useArticlesContext();

  const onPreview = (article) => {
    modal.info({
      centered: true,
      icon: null,
      okText: 'Close',
      title: 'Preview',
      content: <PreviewArticle article={article} />,
    });
  };

  const onDelete = (article) => {
    dispatch(deleteArticle(article));
  };

  const onEdit = (article) => {
    openDrawer.set({ key: DRAWER_ACTIONS.EDIT_ARTICLE, data: article });
  };

  function renderItem(article) {
    return (
      <Card
        hoverable
        size="small"
        style={{ marginBlock: '0.5rem' }}
        styles={{ body: { padding: '0' } }}>
        <List.Item
          key={article.id}
          actions={[
            <Button icon={<EyeOutlined />} onClick={() => onPreview(article)}>
              view
            </Button>,
            <Button icon={<EditOutlined />} onClick={() => onEdit(article)}>
              Edit
            </Button>,
            <DeletePopconfirm
              onDelete={() => onDelete(article)}
              title={`Delete the article: ${article.title}`}
              description="Are you sure you want to delete this article?"
            />,
          ]}
          extra={
            <Image
              style={{
                maxHeight: '8rem',
                maxWidth: '12rem',
                aspectRatio: '3 / 2',
                objectFit: 'cover',
              }}
              alt={article.title}
              preview
              src={article.image}
            />
          }>
          <List.Item.Meta
            title={article.title}
            description={
              <Tag
                color={article.isPublished ? 'success' : 'default'}
                style={{ fontSize: '0.9rem', padding: '0.25rem 0.5rem' }}
                icon={article.isPublished ? <CheckCircleOutlined /> : <ClockCircleOutlined />}>
                {article.isPublished ? 'Published' : 'Draft'}
              </Tag>
            }
          />
          <Typography.Link href={article.link} target="_blank">
            {article.link}
          </Typography.Link>
        </List.Item>
      </Card>
    );
  }

  return (
    <List
      itemLayout="vertical"
      size="large"
      loading={loading}
      pagination={{ pageSize: 10 }}
      dataSource={articles}
      renderItem={renderItem}
      locale={{
        emptyText: <Empty description="No Articles found!" image={Empty.PRESENTED_IMAGE_SIMPLE} />,
      }}
    />
  );
}

function PreviewArticle({ article }) {
  return (
    <Card
      hoverable
      style={{ borderRadius: '20px' }}
      cover={
        <Image
          style={{
            maxWidth: '400px',
            aspectRatio: '3 / 2',
            objectFit: 'cover',
            borderRadius: '20px 20px 0px 0px',
          }}
          preview={false}
          alt={article.title}
          src={article.image}
        />
      }>
      <Card.Meta
        description={
          <Tooltip title={article.link}>
            <Typography.Link strong href={article.link} target="_blank" type="default">
              {article.title}
            </Typography.Link>
          </Tooltip>
        }
      />
    </Card>
  );
}

export default ArticlesList;
