import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import sharedReducer from '../shared/reducer';
import employerDetailsReducer from '../screens/employerDetails/reducer';
import employeeDetailsReducer from '../screens/employeeDetails/reducer';
import loginReducer from '../screens/login/reducer';
import employeesReducer from '../screens/employees/reducer';
import employerDashboardReducer from '../screens/employerDashboard/reducer';
import transactionsReducer from '../screens/transactions/reducer';
import invoicesReducer from '../screens/invoices/reducer';
import invoiceDetailsReducer from '../screens/invoiceDetails/reducer';
import promotionsReducer from '../screens/promotions/reducer';
import promotionDetailsReducer from '../screens/promotionDetails/reducer';
import history from '../utils/history';
import superAdminDashboardReducer from '../screens/superAdminDashboard/reducer';
import employerReducer from '../screens/employers/reducer';
import payCyclesReducer from '../screens/payCycles/reducer';
import startPayPeriodReducer from '../screens/startPayPeriod/reducer';
import usersReducer from '../screens/users/reducer';
import changePasswordReducer from '../screens/changePassword/reducer';
import deductionSummaryReducer from '../screens/deductionSummary/reducer';
import payrollDataReducer from '../screens/payrollData/reducer';
import employeeEligibilityReducer from '../screens/employeeEligibility/reducer';
import forgotPasswordReducer from '../screens/forgotPassword/reducer';
import invitationReducer from '../screens/invitation/reducer';
import payPeriodsReducer from '../screens/payPeriods/reducer';
import advanceDetailsReducer from '../screens/advanceDetails/reducer';
import payrollDataDetailsReducer from '../screens/payrollDataDetails/reducer';
import SettingsReducer from '../screens/settings/reducer';
import MFAReducer from '../screens/MFA/reducer';
import EmployerThemeReducer from '../screens/employerTheme/reducer';
import EmployerReminderReducer from '../screens/employerReminder/reducer';
import LibraryReducer from '../screens/articles/reducer';
import FileModelsReducer from '../screens/fileModels/reducer';

const appReducer = combineReducers({
  router: connectRouter(history),
  shared: sharedReducer,
  auth: loginReducer,
  employerDetails: employerDetailsReducer,
  employeeDetails: employeeDetailsReducer,
  employees: employeesReducer,
  invoices: invoicesReducer,
  employerDashboard: employerDashboardReducer,
  transactions: transactionsReducer,
  invoiceDetails: invoiceDetailsReducer,
  promotions: promotionsReducer,
  promotionDetails: promotionDetailsReducer,
  superAdminDashboard: superAdminDashboardReducer,
  employers: employerReducer,
  payCycles: payCyclesReducer,
  startPayPeriod: startPayPeriodReducer,
  users: usersReducer,
  changePassword: changePasswordReducer,
  deductionSummary: deductionSummaryReducer,
  payrollData: payrollDataReducer,
  employeeEligibility: employeeEligibilityReducer,
  forgotPassword: forgotPasswordReducer,
  // new
  invitation: invitationReducer,
  payPeriods: payPeriodsReducer,
  advanceDetails: advanceDetailsReducer,
  payrollDataDetails: payrollDataDetailsReducer,

  // new
  settings: SettingsReducer,
  MFA: MFAReducer,
  employerTheme: EmployerThemeReducer,
  employerReminder: EmployerReminderReducer,
  library: LibraryReducer,
  fileModels: FileModelsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
    localStorage.clear();
  }
  return appReducer(state, action);
};

export default rootReducer;
