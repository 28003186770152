import { toQuery } from './formatString';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const apiConfig = {
  baseURL: BASE_URL,
  employers: {
    get_employers: ({ query }) => `adv/employers${toQuery(query)}`, // GET
    get_employer: ({ employerId }) => `adv/employers/${employerId}`, // GET
    create_employer: () => `adv/employers`, // POST
    update_employer: ({ employerId, query }) => `adv/employers/${employerId}${toQuery(query)}`,
    register_employer: () => `adv/employers/users`, // POST
    // update employer theme settings
    update_employer_theme_cs: ({ employerId }) =>
      `adv/employers/${employerId}/settings/theme/color-scheme`,
    update_employer_theme_logo: ({ employerId }) =>
      `adv/employers/${employerId}/settings/theme/logo`,
    get_employer_funding_status: ({ employerId }) => `adv/employers/${employerId}/funding-status`,
    // Update employer status
    enable_employer: ({ employerId }) => `adv/employers/${employerId}/enable`, // POST
    disable_employer: ({ employerId }) => `adv/employers/${employerId}/disable`, // POST
    // user
    create_employer_user: ({ employer_id }) =>
      `adv/employers/users/invite?employer_id=${employer_id}`, // POST
    get_employer_users: (query) => `adv/employers/users${toQuery(query)}`, // GET // NEW
    get_employer_user: (employer_user_id) => `adv/employers/users/${employer_user_id}`, // GET
    get_employer_invitations_users: (query) => `adv/employers/invitations${toQuery(query)}`, // GET // NEW
    update_employer_user: (employer_user_id) => `adv/employers/users/${employer_user_id}`, // PUT
    enable_employer_user: ({ employer_user_id }) =>
      `adv/employers/users/${employer_user_id}/enable`, // POST
    disable_employer_user: ({ employer_user_id }) =>
      `adv/employers/users/${employer_user_id}/disable`, // POST
    // Reminder
    get_reminder: (query) => `adv/reminders${toQuery(query)}`,
    create_reminder: () => `adv/reminders`,
    update_reminder: ({ reminder_id }) => `adv/reminders/${reminder_id}`,
  },
  payroll: {
    get_data: ({ query }) =>
      `adv/employers/payroll-data?employer_id=${query.employerId}&payroll_data_type=${query.payroll_data_type}&item_per_page=${query.item_per_page}&number_page=${query.number_page}`,
    get_payroll_file_by_id: ({ payrollId, employerId }) =>
      `adv/employers/payroll-data/${payrollId}?employer_id=${employerId}`, // GET //TODO:
    get_payroll_file_records: ({ payrollId, query }) =>
      `adv/employers/payroll-data/${payrollId}/records${toQuery(query)}`, // GET //TODO:
    update_records: ({ payrollId, recordId }) =>
      `adv/employers/payroll-data/${payrollId}/records/${recordId}`, // PUt

    upload_data: (query) => `adv/employers/payroll-data${toQuery(query)}`, // POST
    integrate_data: () => `adv/payroll`, // POST // TODO
    get_employer_deducation_per_period: ({ employerId, payPeriodId }) =>
      `adv/payroll/${employerId}/deductions/${payPeriodId}`, // GET
    get_employer_deducation: ({ employerId }) => `adv/payroll/${employerId}/deductions`,
    get_deducations: (query) => `adv/deductions${toQuery(query)}`, // GET
    add_deduction: () => `adv/payroll`, // POST // :TODO
    pay_net_minus_deductions: () => `adv/payroll`, // POST // :TODO
    repay_deductions: () => `adv/payroll`, // POST // :TODO

    // new
    get_advances: ({ query }) => `adv/advances${toQuery(query)}`,
    get_advance_by_id: ({ advanceId }) => `adv/advances/${advanceId}`,
  },
  employees: {
    get_employee_employer: ({ employerId, employeeId }) =>
      `adv/employers/${employerId}/employees/${employeeId}`, // GET
    get_employee_employer_balance: ({ employerId, employeeId }) =>
      `adv/employees/${employerId}/balance/${employeeId}`,
    get_employees_by_employer: ({ query }) => `adv/employees${toQuery(query)}`, // GET
    get_employee: ({ employeeId }) => `adv/employees/${employeeId}`, // GET
    update_employee: ({ employeeId }) => `adv/employees/${employeeId}`, // PUT
    create_employee: ({ employerId }) =>
      `adv/employers/payroll-details/employee?employer_id=${employerId}`, // POST
    disable_employee: ({ employeeId }) => `adv/employees/${employeeId}/disable`, // POST
    enable_employee: ({ employeeId }) => `adv/employees/${employeeId}/enable`, // POST
  },
  business: {
    get_businesses: () => `adv/businesses`,
    get_business: ({ businessId }) => `adv/businesses/${businessId}`,
    // get_customer_businesses: () => ``,
  },
  accounts: {
    get_business_accounts: ({ businessId }) => `adv/businesses/${businessId}/account`,
  },
  auth: {
    isSuperAdmin: () => `super-admin`,
    signin: () => `auth/login`,
    signout: () => `auth/employee/signout`,
    forgot_password: () => `auth/employee/forgot-password`,
    change_password: () => `auth/employee/change-password`,
    confirm_password: () => `auth/employee/confirm-password`,
  },

  invitation: {
    verify_token: () => `adv/employers/verify-invitation-token`,
    pre_verify_token: () => `adv/employers/pre-verify-invitation-token`,
  },
  payPeriod: {
    get_payperiods: (query) => `adv/employers/pay-periods${toQuery(query)}`,
    create_payperiod: ({ employerId }) => `adv/employers/pay-periods?employer_id=${employerId}`,
    update_payperiod: ({ employerId, payPeriodId }) =>
      `adv/employers/pay-periods/${payPeriodId}?employer_id=${employerId}`,
    delete_payperiod: ({ employerId, payPeriodId }) =>
      `adv/employers/pay-periods/${payPeriodId}?employer_id=${employerId}`,
    close_payperiod: ({ employerId, payPeriodId }) =>
      `adv/employers/pay-periods/${payPeriodId}/close`,
  },
  mfa: {
    get_mfa_status: (username) => `auth/mfa-status?username=${username}`, // GET
    associate_mfa: () => `auth/associate-mfa`, // POST
    enable_mfa: () => `auth/enable-mfa`, // POST
    disable_mfa: () => `auth/disable-mfa`, // POST
    confirm_mfa_login: () => `auth/confirm-mfa`, // POST
  },
  library: {
    get_categories: () => `adv/library/categories`,
    create_category: () => `adv/library/categories`,
    update_category: () => `adv/library/categories`,
    delete_category: () => `adv/library/categories`,
    get_articles: () => `adv/library/articles`,
    create_article: () => `adv/library/articles`,
    delete_article: () => `adv/library/articles`,
    update_article: () => `adv/library/articles`,
    reorder_categories: () => `adv/library/reorder-categories`,
    reorder_articles: () => `adv/library/reorder-articles`,
  },
  fileModels: {
    get_file_models_config: () => `adv/file-models/config`,
    get_file_models: ({ employerId }) => `adv/file-models?employerId=${employerId}`,
    create_file_model: ({ employerId }) => `adv/file-models?employerId=${employerId}`,
    delete_file_model: ({ employerId, fileModelId }) =>
      `adv/file-models?employerId=${employerId}&fileModelId=${fileModelId}`,
    update_file_model: ({ employerId, fileModelId }) =>
      `adv/file-models?employerId=${employerId}&fileModelId=${fileModelId}`,
  },
};
