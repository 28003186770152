import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import {
  GET_CATEGORIES,
  GET_CATEGORIES_ERROR,
  GET_CATEGORIES_LOADING,
  GET_CATEGORIES_SUCCESS,
  CREATE_CATEGORY,
  CREATE_CATEGORY_ERROR,
  CREATE_CATEGORY_LOADING,
  CREATE_CATEGORY_SUCCESS,
  CREATE_ARTICLE,
  CREATE_ARTICLE_ERROR,
  CREATE_ARTICLE_LOADING,
  CREATE_ARTICLE_SUCCESS,
  GET_ARTICLES_LOADING,
  GET_ARTICLES_SUCCESS,
  GET_ARTICLES_ERROR,
  GET_ARTICLES,
  DELETE_CATEGORY_LOADING,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_ERROR,
  DELETE_CATEGORY,
  DELETE_ARTICLE_LOADING,
  DELETE_ARTICLE_SUCCESS,
  DELETE_ARTICLE_ERROR,
  DELETE_ARTICLE,
  UPDATE_CATEGORY_LOADING,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_ERROR,
  UPDATE_CATEGORY,
  UPDATE_ARTICLE_LOADING,
  UPDATE_ARTICLE_SUCCESS,
  UPDATE_ARTICLE_ERROR,
  UPDATE_ARTICLE,
  REORDER_CATEGORIES,
  REORDER_CATEGORIES_LOADING,
  REORDER_CATEGORIES_SUCCESS,
  REORDER_CATEGORIES_ERROR,
  REORDER_ARTICLES,
  REORDER_ARTICLES_LOADING,
  REORDER_ARTICLES_SUCCESS,
  REORDER_ARTICLES_ERROR,
} from './actions';
import { api } from '../../utils/api';
import { errorhandling } from '../../utils/helper';
import { apiConfig } from '../../utils/apiConfig';
import { message } from 'antd';
import { getArticles as getArticlesAction } from './actions';

// construct the url with query params
function buildURL(base, params) {
  const query = new URLSearchParams(params);

  // convert array values to comma separated string
  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value)) {
      query.set(key, value.join(','));
    }
  }
  return `${base}?${query.toString()}`;
}

function* getCategories({ body }) {
  yield put({ type: GET_CATEGORIES_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'GET',
      url: buildURL(apiConfig.library.get_categories(), body),
    });
    yield put({ type: GET_CATEGORIES_SUCCESS, data });
    // load articles when categories are loaded
    const categoryIdsSet = new Set(data.list.map(({ id }) => id));
    const categoryIds = Array.from(categoryIdsSet);
    // load also uncategorized articles
    yield put(getArticlesAction({ employerId: body.employerId, categoryIds, uncategorized: true }));
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_CATEGORIES_ERROR, errorMessage: error.message });
  }
}

function* createCategory({ body: { employerId, category, onSuccess } }) {
  yield put({ type: CREATE_CATEGORY_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'POST',
      url: buildURL(apiConfig.library.create_category(), { employerId }),
      body: category,
    });
    yield put({ type: CREATE_CATEGORY_SUCCESS, data: data });
    message.success('Category created successfully.');
    onSuccess && onSuccess();
  } catch (error) {
    errorhandling(error);
    yield put({ type: CREATE_CATEGORY_ERROR, errorMessage: 'Failed to create category' });
  }
}

function* deleteCategory({ body: { employerId, categoryId, onSuccess } }) {
  yield put({ type: DELETE_CATEGORY_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'DELETE',
      url: buildURL(apiConfig.library.delete_category(), { employerId, categoryId }),
    });
    yield put({ type: DELETE_CATEGORY_SUCCESS, data: data });
    message.success('Category deleted successfully.');
    onSuccess && onSuccess();
  } catch (error) {
    errorhandling(error);
    yield put({ type: DELETE_CATEGORY_ERROR, errorMessage: 'Failed to delete category' });
  }
}

function* updateCategory({ body: { employerId, categoryId, category, onSuccess } }) {
  yield put({ type: UPDATE_CATEGORY_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'PUT',
      url: buildURL(apiConfig.library.update_category(), { employerId, categoryId }),
      body: category,
    });
    yield put({ type: UPDATE_CATEGORY_SUCCESS, data: data });
    message.success('Category updated successfully.');
    onSuccess && onSuccess();
  } catch (error) {
    errorhandling(error);
    yield put({ type: UPDATE_CATEGORY_ERROR, errorMessage: 'Failed to update category' });
  }
}

function* createArticle({ body: { employerId, article, onSuccess } }) {
  yield put({ type: CREATE_ARTICLE_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'POST',
      url: buildURL(apiConfig.library.create_article(), { employerId }),
      body: article,
    });
    yield put({ type: CREATE_ARTICLE_SUCCESS, data });
    message.success('Article created successfully.');
    onSuccess && onSuccess();
  } catch (error) {
    errorhandling(error);
    yield put({ type: CREATE_ARTICLE_ERROR, errorMessage: 'Failed to create article' });
  }
}

function* getArticles({ body }) {
  yield put({ type: GET_ARTICLES_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'GET',
      url: buildURL(apiConfig.library.get_articles(), body),
    });
    yield put({ type: GET_ARTICLES_SUCCESS, data });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_ARTICLES_ERROR, errorMessage: error.message });
  }
}

function* deleteArticle({ body: { employerId, articleId, onSuccess } }) {
  yield put({ type: DELETE_ARTICLE_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'DELETE',
      url: buildURL(apiConfig.library.delete_article(), { employerId, articleId }),
    });
    yield put({ type: DELETE_ARTICLE_SUCCESS, data: data });
    message.success('Article deleted successfully.');
    onSuccess && onSuccess();
  } catch (error) {
    errorhandling(error);
    yield put({ type: DELETE_ARTICLE_ERROR, errorMessage: 'Failed to delete article' });
  }
}

function* updateArticle({ body: { employerId, articleId, article, onSuccess } }) {
  yield put({ type: UPDATE_ARTICLE_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'PUT',
      url: buildURL(apiConfig.library.update_article(), { employerId, articleId }),
      body: article,
    });
    yield put({ type: UPDATE_ARTICLE_SUCCESS, data });
    message.success('Article updated successfully.');
    onSuccess && onSuccess();
  } catch (error) {
    errorhandling(error);
    yield put({ type: UPDATE_ARTICLE_ERROR, errorMessage: 'Failed to update article' });
  }
}

function* reorderCategories({ body: { onSuccess, employerId, screens } }) {
  yield put({ type: REORDER_CATEGORIES_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'POST',
      url: buildURL(apiConfig.library.reorder_categories(), { employerId }),
      body: { screens },
    });
    yield put({ type: REORDER_CATEGORIES_SUCCESS, data });
    message.success('Categories reordered successfully.');
    onSuccess && onSuccess();
  } catch (error) {
    errorhandling(error);
    yield put({ type: REORDER_CATEGORIES_ERROR, errorMessage: error.message });
  }
}

function* reorderArticles({ body: { onSuccess, employerId, category, articles } }) {
  yield put({ type: REORDER_ARTICLES_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'POST',
      url: buildURL(apiConfig.library.reorder_articles(), { employerId }),
      body: { category, articles },
    });
    yield put({ type: REORDER_ARTICLES_SUCCESS, data });
    message.success('Articles reordered successfully.');
    onSuccess && onSuccess();
  } catch (error) {
    errorhandling(error);
    yield put({ type: REORDER_ARTICLES_ERROR, errorMessage: error.message });
  }
}
/**
 * Root saga manages watcher lifecycle
 */
export default function* watchLibrarySaga() {
  yield takeLatest(GET_CATEGORIES, getCategories);
  yield takeLatest(CREATE_CATEGORY, createCategory);
  yield takeLatest(UPDATE_CATEGORY, updateCategory);
  yield takeEvery(GET_ARTICLES, getArticles);
  yield takeLatest(CREATE_ARTICLE, createArticle);
  yield takeLatest(DELETE_CATEGORY, deleteCategory);
  yield takeLatest(DELETE_ARTICLE, deleteArticle);
  yield takeLatest(UPDATE_ARTICLE, updateArticle);
  yield takeLatest(REORDER_CATEGORIES, reorderCategories);
  yield takeLatest(REORDER_ARTICLES, reorderArticles);
}
