import React, { useEffect, useMemo } from 'react';
import { ConfigProvider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import mapToConfigProviderTheme from '../../../utils/themeMapper';
import {
  employerThemeSelector,
  getEmployerTheme,
  setEmployerTheme,
} from '../../../screens/employerTheme/actions';
import Spinner from '../../../shared/components/Spinner';

export default function EmployerThemeProvider(props) {
  const dispatch = useDispatch();

  const selectedEmployer = useSelector((state) => state.shared.selectedEmployer);
  const { data: employerTheme, loading } = useSelector(employerThemeSelector);

  useEffect(() => {
    const employerId = selectedEmployer?.value;
    // Reset the employer theme in the store if no employer is selected
    if (!employerId) dispatch(setEmployerTheme());
    // Load the employer theme
    else dispatch(getEmployerTheme({ employerId }));
  }, [selectedEmployer]);

  const theme = useMemo(() => {
    return employerTheme?.isEnabled ? mapToConfigProviderTheme(employerTheme) : {};
  }, [employerTheme]);

  return (
    <ConfigProvider theme={theme}>
      <Spinner loading={loading} />
      {props.children}
    </ConfigProvider>
  );
}
