export const GET_CREDENTIALS = 'GET_CREDENTIALS';
export const GET_CREDENTIALS_LOADING = 'GET_CREDENTIALS_LOADING';
export const GET_CREDENTIALS_SUCCESS = 'GET_CREDENTIALS_SUCCESS';
export const GET_CREDENTIALS_ERROR = 'GET_CREDENTIALS_ERROR';

export const GET_FILE_PROCESS_STATUS = 'GET_FILE_PROCESS_STATUS';
export const GET_FILE_PROCESS_STATUS_LOADING = 'GET_FILE_PROCESS_STATUS_LOADING';
export const GET_FILE_PROCESS_STATUS_SUCCESS = 'GET_FILE_PROCESS_STATUS_SUCCESS';
export const GET_FILE_PROCESS_STATUS_ERROR = 'GET_FILE_PROCESS_STATUS_ERROR';

export const UPLOAD_FILE_TO_S3 = 'UPLOAD_FILE_TO_S3';
export const UPLOAD_FILE_TO_S3_LOADING = 'UPLOAD_FILE_TO_S3_LOADING';
export const UPLOAD_FILE_TO_S3_SUCCESS = 'UPLOAD_FILE_TO_S3_SUCCESS';
export const UPLOAD_FILE_TO_S3_ERROR = 'UPLOAD_FILE_TO_S3_ERROR';

export const UPLOAD_PAYROLL_FILE = 'UPLOAD_PAYROLL_FILE';
export const UPLOAD_PAYROLL_FILE_LOADING = 'UPLOAD_PAYROLL_FILE_LOADING';
export const UPLOAD_PAYROLL_FILE_SUCCESS = 'UPLOAD_PAYROLL_FILE_SUCCESS';
export const UPLOAD_PAYROLL_FILE_ERROR = 'UPLOAD_PAYROLL_FILE_ERROR';

export const getCredentials = (body) => ({ type: GET_CREDENTIALS, body });
export const getPayrollDataHistory = (body) => ({ type: GET_FILE_PROCESS_STATUS, body });
export const getPayrollDataHistoryLoading = () => ({ type: GET_FILE_PROCESS_STATUS_LOADING });

export const uploadPayrollFile = ({
  formData,
  employer_id,
  payroll_data_type,
  pay_period_id,
  onUploadProgress,
  onSuccess,
  onError,
}) => ({
  type: UPLOAD_PAYROLL_FILE,
  body: {
    formData,
    employer_id,
    payroll_data_type,
    pay_period_id,
    onUploadProgress,
    onSuccess,
    onError,
  },
});
