import React, { useCallback, useMemo } from 'react';
import { Col, Upload, List, Typography, Button, Flex } from 'antd';
import { DownloadOutlined, InboxOutlined } from '@ant-design/icons';
import { usePreviewContext } from '../context';
import { loadFileData } from '../utils';
import { getColumnType, parserConfig } from '../constants';
import StepWrapper from './StepWrapper';
import StepControls from './StepControls';
import PreviewTable from '../PreviewTable';
import { downloadFile } from '../../../../utils/helper';

const { Text, Link } = Typography;

export default function UploadStep() {
  const { fileModel, inputFile, errors, rawData, fileColumns, step } = usePreviewContext();

  const onFileChange = (file) => {
    inputFile.set(file);
    if (file) loadPreviewData(file);
  };
  const loadPreviewData = (file) => {
    loadFileData(file, parserConfig(fileModel.processingConfig)).then((results) => {
      // { data, errors, columns } = results;
      if (results.errors.length > 0) {
        errors.set(results.errors);
      } else {
        errors.reset();
        rawData.set(results.data);
        fileColumns.set(results.columns);
      }
    });
  };

  const isStepValid = useMemo(
    () => !!inputFile.value && errors.list.length === 0,
    [inputFile.value, errors.list]
  );

  return (
    <>
      <StepWrapper>
        <Col span={16} style={{ padding: '1rem' }}>
          <UploadInput onFileChange={onFileChange} />
          <DownloadTemplate fileModel={fileModel.value} />
        </Col>

        <Col span={8} style={{ marginBlock: '1rem' }}>
          <ExpectedColumnsList fileModelColumns={fileModel.columnsConfig.list} />
        </Col>

        <Col span={inputFile.value ? 24 : 0}>
          <PreviewTable
            data={rawData.list}
            columns={fileColumns.list}
            title={`Raw Data: ${inputFile.value?.name}`}
          />
        </Col>
      </StepWrapper>
      <StepControls
        current={step.currentIndex}
        hasNext={isStepValid}
        isFinal={false}
        onPrev={step.goPrev}
        onNext={step.goNext}
      />
    </>
  );
}

const UploadInput = ({ onFileChange }) => {
  const onChange = (file) => {
    onFileChange(file);
    return false; // Prevent auto upload
  };

  return (
    <Upload.Dragger
      name="file"
      multiple
      hasControlInside={false}
      beforeUpload={onChange}
      accept=".csv"
      showUploadList={false}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
    </Upload.Dragger>
  );
};

const ExpectedColumnsList = ({ fileModelColumns }) => {
  const getItem = ({ inputColumn, inputFormat, targetType }) => (
    <Typography key={inputColumn}>
      <Text strong style={{ marginRight: '0.5rem' }} children={inputColumn} />
      <Text type="secondary" children={getColumnType(targetType)} />
      {targetType === 'Date' ? (
        <Text code style={{ marginLeft: '0.5rem' }} children={inputFormat} />
      ) : null}
    </Typography>
  );
  return (
    <List
      header={<Typography.Text strong>Expected Columns</Typography.Text>}
      rowKey="inputColumn"
      size="small"
      bordered
      dataSource={fileModelColumns || []}
      renderItem={(column) => <List.Item children={getItem(column)} />}
    />
  );
};

const DownloadTemplate = ({ fileModel }) => {
  const { name, processingConfig, columns } = fileModel;

  const downloadCsvExample = useCallback(() => {
    if (!columns) return;
    const headers = columns.map(({ inputColumn }) => inputColumn).join(',');
    const emptyDataRow = processingConfig.delimiter.repeat(columns.length - 1);
    const content = `${headers}\n${emptyDataRow}`;
    downloadFile(content, 'text/csv;charset=utf-8;', `[${name}]-template.csv`);
  }, [columns]);

  return (
    <Flex gap="middle" align="baseline" justify="center" style={{ marginTop: '1rem' }}>
      <Text>Need help with the file format? </Text>

      <Button type="dashed" icon={<DownloadOutlined />} onClick={downloadCsvExample}>
        Download Sample File CSV
      </Button>
    </Flex>
  );
};
