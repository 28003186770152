import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, List } from 'antd';
import { ReorderableList, ReorderableListControls } from './ReorderableList';
import { useArticlesContext } from '../containers/ArticlesContext';
import { reorderArticles } from '../actions';

export default function ReorderArticlesInCategory() {
  const dispatch = useDispatch();
  const { reordering, selectedScreen } = useArticlesContext();
  const { data: articles, loading } = useSelector((state) => state.library.articles);
  const selectedEmployer = useSelector((state) => state.shared.selectedEmployer);
  const [dataSource, setDataSource] = React.useState([]);
  const [isDataChanged, setIsDataChanged] = React.useState(false);

  const categoryId = reordering.value.data?.id;

  React.useEffect(() => {
    const articlesInCategory = articles.byCategory[categoryId]
      .sort((a, b) => a.order - b.order)
      .map(({ article }) => articles.byId[article]);
    setDataSource(articlesInCategory);
    setIsDataChanged(false);
  }, []);

  const onDiscard = () => {
    setIsDataChanged(false);
    reordering.reset();
  };

  const onSave = () => {
    const payload = {
      employerId: selectedEmployer.value,
      category: categoryId,
      articles: dataSource.map(({ id }) => id),
      onSuccess: onDiscard,
    };
    dispatch(reorderArticles(payload));
  };

  const renderArticleItem = (article) => (
    <List.Item.Meta
      avatar={
        <img
          src={article.image}
          alt={article.title}
          style={{ width: 50, height: 50, objectFit: 'cover' }}
        />
      }
      title={article.title}
      description={article.link}
    />
  );

  return (
    <Card
      title={`Reorder Articles in Category: ${reordering.value.data?.name}`}
      loading={loading}
      tabProps={{ size: 'large' }}
      activeTabKey={selectedScreen.value}
      extra={
        <ReorderableListControls
          isLoading={loading}
          isDataChanged={isDataChanged}
          onDiscard={onDiscard}
          onSave={onSave}
        />
      }>
      <ReorderableList
        isDataChanged={{ get: () => isDataChanged, set: setIsDataChanged }}
        renderItem={renderArticleItem}
        dataSource={{ get: () => dataSource, set: setDataSource }}
        emptyDescription="No articles in this category"
        instructionText="Drag and drop the articles below to rearrange their order within this category."
      />
    </Card>
  );
}
