import React, { useState, useMemo, useEffect } from 'react';
import { Button, Form, Select, Input, Space, Popconfirm, Switch, Typography } from 'antd';
import dayjs from 'dayjs';
import { CheckOutlined, EditOutlined } from '@ant-design/icons';

// constants
const separatorOptions = [
  { value: '-', label: 'Hyphen (-)' },
  { value: '/', label: 'Slash (/)' },
  { value: '.', label: 'Dot (.)' },
  { value: ' ', label: 'Space ( )' },
];

const DATE_FORMAT_SEPARATOR = '#';

const dateFormatOptions = [
  { value: ['YYYY', 'MM', 'DD'], label: ['Year', 'Month', 'Day'] },
  { value: ['YYYY', 'DD', 'MM'], label: ['Year', 'Day', 'Month'] },
  { value: ['MM', 'DD', 'YYYY'], label: ['Month', 'Day', 'Year'] },
  { value: ['DD', 'MM', 'YYYY'], label: ['Day', 'Month', 'Year'] },
];

const previewDate = dayjs('2025-01-20', 'YYYY-MM-DD');

/**
 * A component to input a date format. can be used in an antd form
 */
const DateFormatInput = ({ id, value = '', onChange }) => {
  const [separator, setSeparator] = useState('');
  const [selectedFormat, setSelectedFormat] = useState('');
  const [useShortYear, setUseShortYear] = useState(false);

  // initilize the inputs from the value
  useEffect(() => {
    const init = { separator: '-', selectedFormat: ['YYYY', 'MM', 'DD'] };
    if (!!value) {
      init.separator =
        separatorOptions.map(({ value }) => value).find((item) => value.includes(item)) || '-';
      init.selectedFormat = value.replaceAll(init.separator, DATE_FORMAT_SEPARATOR);
    }
    setSeparator(init.separator);
    setSelectedFormat(init.selectedFormat);
  }, []);

  const formatOptions = useMemo(
    () =>
      dateFormatOptions.map(({ value, label }) => ({
        value: value.join(DATE_FORMAT_SEPARATOR),
        label: label.join(separator),
      })),
    [separator]
  );

  const dateFormat = useMemo(() => {
    const format = selectedFormat.replaceAll(DATE_FORMAT_SEPARATOR, separator);
    return useShortYear ? format.replace('YYYY', 'YY') : format;
  }, [selectedFormat, useShortYear, separator]);

  const formatDate = (format) => previewDate.format(format);

  const handleOk = () => onChange(dateFormat);

  return (
    <Space.Compact id={id}>
      <Input
        style={{ marginLeft: '0.5rem' }}
        readOnly
        value={formatDate(value)}
        addonBefore="Format:"
      />
      <Popconfirm
        placement="topLeft"
        title="Edit Date Format"
        onConfirm={handleOk}
        okButtonProps={{ icon: <CheckOutlined />, style: { width: '5rem' } }}
        cancelButtonProps={{ style: { width: '5rem' } }}
        icon={<EditOutlined style={{ color: 'blue' }} />}
        description={
          <Form
            layout="horizontal"
            style={{ minWidth: '20rem' }}
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}>
            <Form.Item label="Format">
              <Select
                style={{ width: '100%' }}
                options={formatOptions}
                value={selectedFormat}
                onChange={setSelectedFormat}
              />
            </Form.Item>

            <Form.Item
              label="Short Year"
              tooltip="Use 2-digit year, e.g., 25 instead of 2025"
              layout="horizontal">
              <Switch defaultChecked checked={useShortYear} onChange={setUseShortYear} />
            </Form.Item>

            <Form.Item label="Separator" layout="horizontal">
              <Select
                value={separator}
                onChange={setSeparator}
                options={separatorOptions}
                style={{ width: '100%' }}
              />
            </Form.Item>

            <Form.Item label="Format Preview">
              <Typography.Text>
                <Typography.Text code>{dateFormat}</Typography.Text>, e.g.,{' '}
                <Typography.Text code>{formatDate(dateFormat)}</Typography.Text>
              </Typography.Text>
            </Form.Item>
          </Form>
        }>
        <Button type="primary">Change</Button>
      </Popconfirm>
    </Space.Compact>
  );
};

export default DateFormatInput;
