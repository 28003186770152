import React from 'react';
import { ColorPicker, Form } from 'antd';
import FormItemLabel from './FormItemLabel';
import { isColorTransparent } from '../../../utils/formatString';

export default function ColorInputField({ path, label, description }) {
  const getValueFromEvent = (event) => event.toHex();

  const formatColorText = (color) => {
    return isColorTransparent(color?.toHexString()) ? 'Not Set' : color.toHexString();
  };
  return (
    <Form.Item
      name={path}
      getValueFromEvent={getValueFromEvent}
      label={<FormItemLabel label={label} description={description} />}>
      <ColorPicker size="large" allowClear showText={formatColorText} />
    </Form.Item>
  );
}
