import React from 'react';
import { Card, Flex, Steps, theme } from 'antd';
import { STEPS, steps } from '../constants';
import { usePreviewContext } from '../context';
import UploadStep from './upload';
import ReviewStep from './review';
import ErrorsList from '../ErrorsList';
import CompleteStep from './complete';

export default function ImportCSVSteps({ onComplete }) {
  const { step } = usePreviewContext();

  return (
    <Flex vertical gap="middle" style={{ width: '100%' }}>
      <Card style={{ position: 'sticky', top: '-1.5rem', zIndex: 99999, padding: 0 }}>
        <Steps current={step.currentIndex} items={steps} progressDot />
      </Card>
      <ErrorsList />
      {step.currentKey === STEPS.UPLOAD && <UploadStep />}
      {step.currentKey === STEPS.REVIEW && <ReviewStep />}
      {step.currentKey === STEPS.COMPLETE && <CompleteStep onComplete={onComplete} />}
    </Flex>
  );
}
