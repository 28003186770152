import React from 'react';
import { Button, Checkbox, Col, Drawer, Flex, Form, Input, Row, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';
import { CategoryIconsList, DRAWER_ACTIONS, MobileAppScreens } from '../constants';
import { useArticlesContext } from '../containers/ArticlesContext';
import { createCategory, updateCategory } from '../actions';

const screensOptions = [{ label: 'All Screens', value: 'ALL' }, ...MobileAppScreens];
const iconOptions = CategoryIconsList.map(({ name, value, icon: Icon }) => ({
  label: (
    <Flex align={'center'} gap={'middle'}>
      <Icon size={24} /> {name}
    </Flex>
  ),
  value,
}));

export default function AddOrEditCategory() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { openDrawer } = useArticlesContext();
  const selectedEmployer = useSelector((state) => state.shared.selectedEmployer);
  const { action_loading, data: categories } = useSelector((state) => state.library.categories);

  const isOpen = React.useMemo(
    () =>
      openDrawer.value.key === DRAWER_ACTIONS.NEW_CATEGORY ||
      openDrawer.value.key === DRAWER_ACTIONS.EDIT_CATEGORY,
    [openDrawer.value]
  );

  const isEditMode = openDrawer.value.key === DRAWER_ACTIONS.EDIT_CATEGORY;
  const drawerData = openDrawer.value.data;

  // Set initial form values when in edit mode
  React.useEffect(() => {
    if (isEditMode) {
      const { name, icon, description, screens } = drawerData;
      const screensNames = screens.map(({ name }) => name);
      if (screensNames.length === MobileAppScreens.length) screensNames.push('ALL');
      form.setFieldsValue({ name, icon, description, screens: screensNames });
    }
  }, [drawerData, isEditMode]);

  const onClose = () => {
    if (action_loading) return;
    form.resetFields(); // reset the form
    openDrawer.reset(); // close the drawer
  };

  function onSubmit(values) {
    if (isEditMode) {
      onUpdate(values);
    } else {
      onCreate(values);
    }
  }

  const onCreate = (values) => {
    const category = { ...values, screens: values.screens.filter((screen) => screen !== 'ALL') };
    dispatch(createCategory({ employerId: selectedEmployer.value, category, onSuccess: onClose }));
  };

  const onUpdate = (values) => {
    const selectedScreens = values.screens.filter((screen) => screen !== 'ALL');
    const screens = calculateScreenOrders(selectedScreens);
    const payload = {
      employerId: selectedEmployer.value,
      categoryId: drawerData.id,
      category: { ...values, screens },
      onSuccess: onClose,
    };

    dispatch(updateCategory(payload));
  };

  const normalizeScreens = (value, prevValue) => {
    const hasAll = value.includes('ALL');
    const hadAll = prevValue?.includes('ALL');
    const selectAll = [...MobileAppScreens.map(({ value }) => value), 'ALL'];
    // toggle all when ALL is added or removed
    if (hasAll !== hadAll) return hasAll ? selectAll : [];
    // remove ALL if any other screen is removed
    if (hasAll && value.length < selectAll.length) return value.filter((v) => v !== 'ALL');
    // add ALL if all other screens are selected
    if (!hasAll && value.length === selectAll.length - 1) return selectAll;
    return value;
  };

  function calculateScreenOrders(selectedScreens) {
    const screens = [];
    for (const key of selectedScreens) {
      let newOrder = 0;
      if (!categories.byScreenName[key]) categories.byScreenName[key] = [];
      for (const { id, order } of categories.byScreenName[key]) {
        if (id === drawerData.id) {
          newOrder = order;
          break;
        }
        if (order >= newOrder) newOrder = order + 1;
      }
      screens.push({ screenName: key, categoryOrder: newOrder });
    }
    return screens;
  }

  return (
    <Drawer
      open={isOpen}
      destroyOnClose={true}
      title={isEditMode ? 'Edit Category' : 'Create a new category'}
      maskClosable={false}
      onClose={onClose}
      width={720}
      extra={<Button onClick={onClose} icon={<CloseOutlined />} children={'Cancel'} />}>
      <Form
        layout="vertical"
        size={'large'}
        form={form}
        onFinish={onSubmit}
        disabled={action_loading}
        initialValues={{ name: '', icon: '', description: '', screens: ['WELLBEING'] }}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: 'Please enter category name' }]}>
              <Input placeholder="ei. Financial Literacy" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="icon"
              label="Icon"
              rules={[{ required: true, message: 'Please select an icon' }]}>
              <Select placeholder="Select an icon" allowClear options={iconOptions} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="description" label="Description">
              <Input.TextArea rows={4} placeholder="Description" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="screens"
              label="Display location"
              rules={[
                { required: true, message: 'Please select where you want to show this category' },
              ]}
              tooltip="Select the pages where you want to show this category"
              normalize={normalizeScreens}>
              <Checkbox.Group options={screensOptions} style={{ margin: '0.25rem 0' }} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16} justify={'end'} style={{ marginTop: '1.5rem' }}>
          <Col span={10}>
            <Form.Item label={null}>
              <Button type="primary" block htmlType={'submit'} loading={action_loading}>
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}
