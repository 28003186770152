import React, { useState, useEffect } from 'react';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';
import { Card, List, Empty, Typography, Button, Flex, Popconfirm } from 'antd';
import { SaveOutlined, CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { MdDragIndicator } from 'react-icons/md';

function SortableListItem({ children, item }) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: item.id,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: 'move',
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 1000,
          opacity: 0.9,
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          backgroundColor: '#f5f5f5',
        }
      : {}),
  };

  return (
    <List.Item
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      actions={[<MdDragIndicator size={'2rem'} />]}>
      {children}
    </List.Item>
  );
}

export function ReorderableList({
  dataSource,
  renderItem,
  isDataChanged,
  emptyDescription = 'No items found',
  instructionText = 'Drag and drop the items below to rearrange their order.',
}) {
  const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 10 } }));

  const onDragEnd = ({ active, over }) => {
    if (!over || active.id === over.id) return;
    dataSource.set((prev) => {
      const activeIndex = prev.findIndex((i) => i.id === active.id);
      const overIndex = prev.findIndex((i) => i.id === over.id);

      return activeIndex !== -1 && overIndex !== -1
        ? arrayMove(prev, activeIndex, overIndex)
        : prev;
    });
    isDataChanged.set(true);
  };

  return (
    <>
      <Typography.Paragraph type="secondary" style={{ marginBottom: '1rem' }}>
        {instructionText}
      </Typography.Paragraph>

      <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          items={dataSource.get().map((i) => i.id)}
          strategy={verticalListSortingStrategy}>
          <List
            bordered
            dataSource={dataSource.get()}
            renderItem={(item) => (
              <SortableListItem key={item.id} item={item}>
                {renderItem(item)}
              </SortableListItem>
            )}
            locale={{
              emptyText: (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={emptyDescription} />
              ),
            }}
          />
        </SortableContext>
      </DndContext>
    </>
  );
}

export function ReorderableListControls({ isDataChanged, onSave, onDiscard, isLoading }) {
  return (
    <Flex gap="middle">
      <Flex gap="middle">
        <Button
          loading={isLoading}
          type="primary"
          icon={<SaveOutlined />}
          onClick={onSave}
          disabled={!isDataChanged}>
          Save changes
        </Button>

        <Popconfirm
          onConfirm={onDiscard}
          placement="topRight"
          title="Discard changes"
          description="Are you sure you want to discard changes?"
          disabled={!isDataChanged}
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}>
          <Button
            danger
            icon={<CloseOutlined />}
            loading={isLoading}
            onClick={() => !isDataChanged && onDiscard()}>
            Discard
          </Button>
        </Popconfirm>
      </Flex>
    </Flex>
  );
}
