import React, { useState } from 'react';
import { Card } from 'antd';
import UpsertFileModelForm, { NewFileModelButton } from './UpsertFileModelForm';
import { useSelector } from 'react-redux';
import FileModelsList from './FileModelsList';

export default function FileModels({ employerId }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [updatingFileModel, setUpdatingFileModel] = useState(null);

  const { data: config, loading: loadingConfig } = useSelector((state) => state.fileModels.config);
  const { data: list, loading: loadingList } = useSelector((state) => state.fileModels.list);

  const openDrawer = () => setIsDrawerOpen(true);
  const onEditFileModel = (record) => {
    setUpdatingFileModel(record);
    openDrawer();
  };

  const drawerProps = {
    employerId,
    updatingFileModel,
    isOpen: isDrawerOpen,
    fileModelsConfig: config,
    closeDrawer: () => {
      setIsDrawerOpen(false);
      setUpdatingFileModel(null);
    },
  };

  return (
    <Card
      title="File Models"
      loading={loadingList}
      extra={<NewFileModelButton onClick={openDrawer} disabled={loadingConfig} />}>
      <FileModelsList list={list} loading={loadingList} onEdit={onEditFileModel} />
      <UpsertFileModelForm {...drawerProps} />
    </Card>
  );
}
