import React, { useEffect, useMemo, useState } from 'react';
import { Form, Select, Skeleton, Typography } from 'antd';
import { groupBy } from 'lodash';
import { FileTypes } from './constants';

const { Text } = Typography;
const fileTypeLabels = {
  [FileTypes.EARNINGS]: 'Employee Earnings',
  [FileTypes.DAILY_SHIFT_EARNINGS]: 'Daily Shift Earnings',
  [FileTypes.EMPLOYEE_DETAILS]: 'Employee Details',
};

/**
 * Given a list of file models and a list of file types, this function returns
 * an object with two properties: 'list' and 'firstValue'. The 'list' property
 * is an array of objects that can be used as options for an Ant Design Select
 * component. The 'firstValue' property is the value of the first option, or
 * null if there are no options. The options are grouped by file type if there
 * are multiple file types, otherwise they are not grouped.
 */
const getFileModelsOptions = ({ list = [], fileTypes = [] }) => {
  const filtered = list.filter((item) => fileTypes.includes(item.fileType));
  if (!filtered.length) return { options: [], firstValue: null };

  if (fileTypes.length === 1) {
    const optionsList = filtered.map((item) => ({ label: item.name, value: item.id }));
    return { list: optionsList, firstValue: optionsList?.[0].value || null };
  }

  const grouped = groupBy(filtered, 'fileType');
  const optionsList = Object.entries(grouped).map(([fileType, items]) => ({
    label: <Text type="secondary">{fileTypeLabels[fileType]}</Text>,
    options: items.map((item) => ({ label: item.name, value: item.id })),
  }));

  const firstOptionValue = optionsList?.[0]?.options?.[0]?.value || null;
  return { list: optionsList, firstValue: firstOptionValue };
};

export default function SelectFileModel({
  fileModelsList,
  fileTypesList,
  value,
  onChange,
  isLoading,
  fromItemProps = {},
}) {
  const options = useMemo(() => {
    if (!fileModelsList) return [];
    return getFileModelsOptions({ list: fileModelsList, fileTypes: fileTypesList });
  }, [JSON.stringify(fileModelsList), JSON.stringify(fileTypesList)]);

  const [selectedId, setSelectedId] = useState(options.firstValue || value?.id || null);

  const onSelect = (selectedId) => setSelectedId(selectedId);

  useEffect(() => {
    onChange(fileModelsList.find((item) => item.id === selectedId));
  }, [selectedId]);

  return (
    <Form.Item
      label="File Model"
      layout="horizontal"
      labelAlign="left"
      initialValue={selectedId}
      {...fromItemProps}>
      {isLoading ? (
        <Skeleton.Input active block />
      ) : (
        <Select
          options={options.list}
          value={selectedId}
          onChange={onSelect}
          placeholder="Select a File Model"
        />
      )}
    </Form.Item>
  );
}
