import { combineReducers } from 'redux';
import {
  CREATE_ARTICLE_ERROR,
  CREATE_ARTICLE_LOADING,
  CREATE_ARTICLE_SUCCESS,
  CREATE_CATEGORY_ERROR,
  CREATE_CATEGORY_LOADING,
  CREATE_CATEGORY_SUCCESS,
  DELETE_ARTICLE_ERROR,
  DELETE_ARTICLE_LOADING,
  DELETE_ARTICLE_SUCCESS,
  DELETE_CATEGORY_ERROR,
  DELETE_CATEGORY_LOADING,
  DELETE_CATEGORY_SUCCESS,
  GET_ARTICLES_ERROR,
  GET_ARTICLES_LOADING,
  GET_ARTICLES_SUCCESS,
  GET_CATEGORIES_ERROR,
  GET_CATEGORIES_LOADING,
  GET_CATEGORIES_SUCCESS,
  REORDER_ARTICLES_ERROR,
  REORDER_ARTICLES_LOADING,
  REORDER_ARTICLES_SUCCESS,
  REORDER_CATEGORIES_ERROR,
  REORDER_CATEGORIES_LOADING,
  REORDER_CATEGORIES_SUCCESS,
  UPDATE_ARTICLE_ERROR,
  UPDATE_ARTICLE_LOADING,
  UPDATE_ARTICLE_SUCCESS,
  UPDATE_CATEGORY_ERROR,
  UPDATE_CATEGORY_LOADING,
  UPDATE_CATEGORY_SUCCESS,
} from './actions';
import { ArticlesUtils, CategoriesUtils } from './stateUtils';

/* -------------------------------------------------------------------------- */
/*                                 Categories                                 */
/* -------------------------------------------------------------------------- */

const initialCategoriesStateObject = {
  loading: false,
  action_loading: false,
  data: { byId: {}, byScreenName: {}, total: 0 },
  error: '',
};

const categories = (state = initialCategoriesStateObject, action) => {
  switch (action.type) {
    case GET_CATEGORIES_LOADING:
    case REORDER_CATEGORIES_LOADING:
      return { ...state, loading: true, error: '' };
    case GET_CATEGORIES_SUCCESS:
      return { ...state, loading: false, data: CategoriesUtils.parseGet(action.data), error: '' };

    case GET_CATEGORIES_ERROR:
    case REORDER_CATEGORIES_ERROR:
      return { ...state, loading: false, error: action.errorMessage };

    case CREATE_CATEGORY_ERROR:
    case DELETE_CATEGORY_ERROR:
    case UPDATE_CATEGORY_ERROR:
      return { ...state, action_loading: false, error: action.errorMessage };

    case CREATE_CATEGORY_LOADING:
    case DELETE_CATEGORY_LOADING:
    case UPDATE_CATEGORY_LOADING:
      return { ...state, action_loading: true };

    case CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        action_loading: false,
        data: CategoriesUtils.parseAdd(state.data, action.data),
        error: '',
      };
    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        action_loading: false,
        data: CategoriesUtils.parseDelete(state.data, action.data),
      };

    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        action_loading: false,
        data: CategoriesUtils.parseUpdate(state.data, action.data),
      };

    case REORDER_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: CategoriesUtils.parseReorder(state.data, action.data),
      };
    default:
      return state;
  }
};

/* -------------------------------------------------------------------------- */
/*                                  Articles                                  */
/* -------------------------------------------------------------------------- */

const initialArticlesStateObject = {
  loading: false,
  action_loading: false,
  data: { byId: {}, byCategory: {}, uncategorized: [] },
  error: '',
};

const articles = (state = initialArticlesStateObject, action) => {
  switch (action.type) {
    case GET_ARTICLES_LOADING:
    case REORDER_ARTICLES_LOADING:
      return { ...state, loading: true, error: '' };

    case GET_ARTICLES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: ArticlesUtils.parseGet(action.data),
        error: '',
      };

    case GET_ARTICLES_ERROR:
    case REORDER_ARTICLES_ERROR:
      return { ...state, loading: false, error: action.errorMessage };

    case CREATE_ARTICLE_ERROR:
    case DELETE_ARTICLE_ERROR:
    case UPDATE_ARTICLE_ERROR:
      return { ...state, action_loading: false, error: action.errorMessage };

    case CREATE_ARTICLE_SUCCESS:
      return {
        ...state,
        action_loading: false,
        data: ArticlesUtils.parseAdd(state.data, action.data),
      };

    case DELETE_ARTICLE_SUCCESS:
      return {
        ...state,
        action_loading: false,
        data: ArticlesUtils.parseDelete(state.data, action.data),
      };

    case UPDATE_ARTICLE_SUCCESS:
      return {
        ...state,
        action_loading: false,
        data: ArticlesUtils.parseUpdate(state.data, action.data),
      };

    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        data: ArticlesUtils.parseDeleteCategory(state.data, action.data),
      };

    case REORDER_ARTICLES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: ArticlesUtils.parseReorder(state.data, action.data),
      };

    case CREATE_ARTICLE_LOADING:
    case DELETE_ARTICLE_LOADING:
    case UPDATE_ARTICLE_LOADING:
      return { ...state, action_loading: true };

    default:
      return state;
  }
};

export default combineReducers({
  categories,
  articles,
});
