import dayjs from 'dayjs';
import { groupBy, isArray } from 'lodash';
import { parse } from 'papaparse';
import { ErrorTypes } from './constants';

const isEmptyRow = (row) => {
  const list = isArray(row) ? row : Object.values(row);
  return list.every((val) => val === null || val === '');
};

export const loadFileData = (file, config) => {
  return new Promise((resolve, reject) => {
    parse(file, {
      ...config,
      complete: (results) => {
        const output = { data: [], errors: [], columns: [] };
        // check if there is any error in the file
        if (results.errors.length > 0) {
          const groupedErrors = groupErrorsByCode(results.errors);
          output.errors.push(...groupedErrors);
        }

        output.columns = getFileColumns(results.data, results.meta.fields);

        // remove empty lines
        results.data = results.data.filter((row) => !isEmptyRow(row));

        // check if data is empty
        if (results.data.length === 0) {
          output.errors.push(ErrorTypes.EMPTY_FILE_ERROR);
        }

        // handle the presence of headers
        if (!config.header) {
          output.data = results.data.map((row) =>
            row.reduce((acc, value, index) => {
              acc[output.columns[index]] = value;
              return acc;
            }, {})
          );
        } else {
          output.data = results.data;
        }
        resolve(output);
      },
      error: reject,
    });
  });
};

export const getFileColumns = (data, fields) => {
  if (!fields) {
    const columnCount = Math.max(...data.map((row) => row.length), 0);
    return Array.from({ length: columnCount }, (_, i) => `Column ${i + 1}`);
  }
  return fields;
};

export const groupErrorsByCode = (errors) => {
  return Object.entries(groupBy(errors, 'code')).map(([key, value]) => ({
    code: key,
    message: value[0].message,
    rows: value.map((x) => x.row),
  }));
};

export const mapColumns = (expectedColumns, receivedFields, getFuse) => {
  const fuse = getFuse(receivedFields);
  return expectedColumns.reduce((acc, source) => {
    if (receivedFields.includes(source.inputColumn)) {
      acc[source.targetField] = source.inputColumn;
    } else {
      const bestMatch = fuse.search(source.inputColumn)?.[0]?.item || null;
      acc[source.targetField] = bestMatch;
    }
    return acc;
  }, {});
};

export const transformColumnValue = (column, value) => {
  if (!value) return null;

  if (column?.targetType === 'Date') {
    const date = dayjs(value, column.inputFormat);
    return date.isValid() ? date.format(column.targetFormat) : null;
  }

  if (column?.targetType === 'Number') {
    const number = Number(value);
    return isNaN(number) ? null : number;
  }
  return value;
};

export const validateColumnValue = (column, value, rowIndex) => {
  const { isRequired, targetType, inputColumn, inputFormat } = column;

  if (isRequired && !value) {
    return { error: ErrorTypes.REQUIRED_FIELD_ERROR(inputColumn, rowIndex) };
  }
  if (value) {
    if (targetType === 'Date' && !dayjs(value, inputFormat, true).isValid()) {
      return { error: ErrorTypes.INVALID_DATE_ERROR(inputColumn, rowIndex) };
    } else if (targetType === 'Number' && isNaN(Number(value))) {
      return { error: ErrorTypes.INVALID_NUMBER_ERROR(inputColumn, rowIndex) };
    }
    return { data: value };
  }
};

export const validateRequiredColumns = (columnsConfig, columnMapping) => {
  for (const column of columnsConfig.list) {
    if (column.isRequired && !columnMapping[column.targetField]) {
      throw ErrorTypes.REQUIRED_FIELD_ERROR(column.inputColumn);
    }
  }
};

export const validateBatch = (batch, startIndex, columnConfig, sourceColumn) => {
  for (let i = 0; i < batch.length; i++) {
    const rowIndex = startIndex + i; // the absolute index of the row in the original list
    const row = batch[i];
    const value = row[sourceColumn];
    const { error } = validateColumnValue(columnConfig, value, rowIndex);
    if (error) throw error;
  }
};
