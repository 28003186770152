import React, { useMemo, useState } from 'react';
import { Table } from 'antd';
import { listColumns } from '../constants';
import PreviewFileModel from './PreviewDrawer';
import { useDispatch, useSelector } from 'react-redux';
import { deleteFileModel } from '../actions';

function FileModelsList({ loading, list, onEdit }) {
  const dispatch = useDispatch();
  const selectedEmployer = useSelector((state) => state.shared.selectedEmployer);
  const [selectedItem, setSelectedItem] = useState(null);
  const onPreview = (record) => setSelectedItem(record);
  const onCancel = () => setSelectedItem(null);

  const onDelete = (record) => {
    dispatch(deleteFileModel({ employerId: selectedEmployer.value, fileModelId: record.id }));
  };

  const columns = useMemo(
    () => listColumns({ onPreview, onDelete, onEdit }),
    [selectedItem, onPreview]
  );
  const dataSource = useMemo(() => list.map((item) => ({ ...item, key: item.id })), [list]);
  return (
    <>
      <Table loading={loading} dataSource={dataSource} columns={columns} />
      <PreviewFileModel isOpen={!!selectedItem} fileModel={selectedItem} onCancel={onCancel} />
    </>
  );
}

export default FileModelsList;
