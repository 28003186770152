import { Button, Col, Typography } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { usePreviewContext } from '../context';
import { transformColumnValue } from '../utils';
import StepControls from './StepControls';
import StepWrapper from './StepWrapper';
import PreviewTable from '../PreviewTable';
import { DownloadOutlined } from '@ant-design/icons';
import { convertToCSV, exportAsCSV } from '../../../../utils/helper';

const { Text } = Typography;

export default function ConfirmStep({ onComplete }) {
  const { fileModel, step, columnMapping, rawData, inputFile } = usePreviewContext();
  const [processedData, setProcessedData] = useState({ list: [], total: 0, headers: [] });
  const [dataSource, setDataSource] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const columns = fileModel.columnsConfig.list.map((item) => item.inputColumn);

  useEffect(() => {
    if (!rawData.list) return;
    setIsLoading(true);
    const headers = Object.entries(columnMapping.value).map(([label, key]) => ({ label, key }));
    const processd = [];
    const toDisplay = [];
    rawData.list.forEach((row) => {
      const processdRow = {};
      const toDisplayRow = {};

      headers.forEach(({ key: fileColumn, label: targetColumn }) => {
        const value = row?.[fileColumn] || '';
        const config = fileModel.columnsConfig.byTargetField[targetColumn];
        processdRow[fileColumn] = transformColumnValue(config, value);
        toDisplayRow[config.inputColumn] = transformColumnValue(config, value);
      });
      processd.push(processdRow);
      toDisplay.push(toDisplayRow);
    });
    setDataSource(toDisplay);
    setProcessedData({ list: processd, total: processd.length, headers });
    setIsLoading(false);
  }, [rawData.list]);

  const onSubmit = () => {
    const delimiter = fileModel.processingConfig.delimiter;
    const csvContent = convertToCSV(processedData.list, processedData.headers, delimiter);
    const csvFile = new Blob([csvContent], {
      type: 'text/csv;charset=utf-8;',
      filename: inputFile.value.name,
    });
    onComplete({ file: csvFile, total: processedData.total });
  };

  const DownloadButton = () => {
    const delimiter = fileModel.processingConfig.delimiter;
    return (
      <Button
        type="dashed"
        icon={<DownloadOutlined />}
        onClick={() => exportAsCSV({ ...processedData, config: { delimiter } })}>
        Download as CSV
      </Button>
    );
  };

  return (
    <>
      <StepWrapper>
        <Text type="secondary">
          Please review the rows of your file before confirming the upload
        </Text>

        <Col span={24}>
          <PreviewTable
            data={dataSource}
            columns={columns}
            maxHeight="30rem"
            loading={isLoading}
            title={`Processed ${processedData?.total} rows`}
            extra={<DownloadButton />}
          />
        </Col>
      </StepWrapper>
      <StepControls
        current={step.currentIndex}
        hasNext={true}
        loadingNext={isLoading}
        isFinal={true}
        onPrev={step.goPrev}
        onNext={onSubmit}
      />
    </>
  );
}
