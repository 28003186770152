import { Space, Typography } from 'antd';
import { startCase } from 'lodash';
import React from 'react';

const { Text, Paragraph } = Typography;

const FormItemLabel = ({ label, description }) => (
  <Space direction="vertical">
    <Text strong>{startCase(label)}</Text>
    <Paragraph type={'secondary'}>{description}</Paragraph>
  </Space>
);

export default FormItemLabel;
