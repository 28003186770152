import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { App, Card, List } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { HiOutlineSquares2X2 } from 'react-icons/hi2';
import { reorderCategories } from '../actions';
import { ReorderableList, ReorderableListControls } from './ReorderableList';
import { CategoryIconsList, MobileAppScreens } from '../constants';
import { useArticlesContext } from '../containers/ArticlesContext';

const iconsByCategoryMap = CategoryIconsList.reduce((acc, { value, icon }) => {
  acc[value] = icon;
  return acc;
}, {});

const tabList = MobileAppScreens.map(({ value, label }) => ({ key: value, tab: label }));

export default function ReorderCategories() {
  const dispatch = useDispatch();
  const { modal } = App.useApp();
  const { selectedScreen, reordering } = useArticlesContext();
  const { data: categories, loading } = useSelector((state) => state.library.categories);
  const selectedEmployer = useSelector((state) => state.shared.selectedEmployer);
  const [dataSource, setDataSource] = React.useState([]);
  const [isDataChanged, setIsDataChanged] = React.useState(false);

  React.useEffect(() => {
    if (selectedScreen.value === 'ALL') selectedScreen.set(MobileAppScreens[0].value);
  }, [selectedScreen.value]);

  React.useEffect(() => {
    const categoriesInScreen = (categories.byScreenName[selectedScreen.value] || [])
      .map(({ id }) => categories.byId[id])
      .filter(({ isDeleted }) => !isDeleted);
    setDataSource(categoriesInScreen);
    setIsDataChanged(false);
  }, [categories, selectedScreen.value]);

  const onDiscard = () => {
    setIsDataChanged(false);
    reordering.reset();
  };
  const onSave = () => {
    const payload = {
      employerId: selectedEmployer.value,
      onSuccess: onDiscard,
      screens: [
        {
          screenName: selectedScreen.value,
          categoryIds: dataSource.map(({ id }) => id),
        },
      ],
      employerId: selectedEmployer.value,
    };
    dispatch(reorderCategories(payload));
  };

  const onTabChange = (key, confirmed = false) => {
    if (isDataChanged && !confirmed) {
      modal.confirm({
        title: 'Discard changes?',
        content: 'Switching screen will overwrite your current changes.',
        onOk: () => onTabChange(key, true),
        icon: <QuestionCircleOutlined />,
      });
    } else {
      selectedScreen.set(key);
    }
  };

  const renderCategoryItem = (category) => {
    const Icon = iconsByCategoryMap[category.icon] || HiOutlineSquares2X2;

    return (
      <List.Item.Meta
        avatar={<Icon size={'2rem'} />}
        title={category.name}
        description={category.description}
      />
    );
  };

  return (
    <Card
      title="Reorder Categories"
      loading={loading}
      tabList={tabList}
      onTabChange={onTabChange}
      tabProps={{ size: 'large' }}
      activeTabKey={selectedScreen.value}
      tabBarExtraContent={
        <ReorderableListControls
          isLoading={loading}
          isDataChanged={isDataChanged}
          onDiscard={onDiscard}
          onSave={onSave}
        />
      }>
      <ReorderableList
        dataSource={{ get: () => dataSource, set: setDataSource }}
        isDataChanged={{ get: () => isDataChanged, set: setIsDataChanged }}
        renderItem={renderCategoryItem}
        emptyDescription="No categories found."
        instructionText="Drag and drop the categories below to rearrange their order for the current screen."
      />
    </Card>
  );
}
