import { call, delay, put, takeLatest } from 'redux-saga/effects';
import {
  GET_FILE_MODELS_CONFIG,
  GET_FILE_MODELS_CONFIG_LOADING,
  GET_FILE_MODELS_CONFIG_ERROR,
  GET_FILE_MODELS_CONFIG_SUCCESS,
  CREATE_FILE_MODEL,
  CREATE_FILE_MODEL_LOADING,
  CREATE_FILE_MODEL_ERROR,
  CREATE_FILE_MODEL_SUCCESS,
  GET_FILE_MODELS,
  GET_FILE_MODELS_LOADING,
  GET_FILE_MODELS_ERROR,
  GET_FILE_MODELS_SUCCESS,
  DELETE_FILE_MODEL_LOADING,
  DELETE_FILE_MODEL_SUCCESS,
  DELETE_FILE_MODEL_ERROR,
  DELETE_FILE_MODEL,
  UPDATE_FILE_MODEL,
  UPDATE_FILE_MODEL_LOADING,
  UPDATE_FILE_MODEL_SUCCESS,
  UPDATE_FILE_MODEL_ERROR,
} from './actions';
import { api } from '../../utils/api';
import { errorhandling } from '../../utils/helper';
import { apiConfig } from '../../utils/apiConfig';
import { message } from 'antd';

function* getFileModelsConfig({ body }) {
  yield put({ type: GET_FILE_MODELS_CONFIG_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'GET',
      url: apiConfig.fileModels.get_file_models_config(),
    });

    yield put({ type: GET_FILE_MODELS_CONFIG_SUCCESS, data: data });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_FILE_MODELS_CONFIG_ERROR, errorMessage: error.message });
  }
}

function* getFileModelsList({ body }) {
  yield put({ type: GET_FILE_MODELS_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'GET',
      url: apiConfig.fileModels.get_file_models({ employerId: body.employerId }),
    });
    yield put({ type: GET_FILE_MODELS_SUCCESS, data: data });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_FILE_MODELS_ERROR, errorMessage: error.message });
  }
}

function* createFileModel({ body }) {
  yield put({ type: CREATE_FILE_MODEL_LOADING });
  message.loading({ key: 'create-file-model', content: 'Creating file model...' });
  try {
    const { employerId, onSuccess, ...payload } = body;
    const { data } = yield call(api, {
      method: 'POST',
      url: apiConfig.fileModels.create_file_model({ employerId }),
      body: payload,
    });
    yield put({ type: CREATE_FILE_MODEL_SUCCESS, data: data });
    message.success({ key: 'create-file-model', content: 'File model created successfully!' });
    onSuccess && onSuccess();
  } catch (error) {
    errorhandling(error);
    yield put({ type: CREATE_FILE_MODEL_ERROR, errorMessage: error.message });
    message.error({ key: 'create-file-model', content: 'Failed to create file model' });
  }
}

function* deleteFileModel({ body: { employerId, fileModelId } }) {
  yield put({ type: DELETE_FILE_MODEL_LOADING });
  message.loading({ key: 'delete-file-model', content: 'Deleting file model...' });
  try {
    const { data } = yield call(api, {
      method: 'DELETE',
      url: apiConfig.fileModels.delete_file_model({ fileModelId, employerId }),
    });
    yield put({ type: DELETE_FILE_MODEL_SUCCESS, data });
    message.success({ key: 'delete-file-model', content: 'File model deleted successfully!' });
  } catch (error) {
    errorhandling(error);
    yield put({ type: DELETE_FILE_MODEL_ERROR, errorMessage: error.message });
    message.error({ key: 'delete-file-model', content: 'Failed to delete file model' });
  }
}

function* updateFileModel({ body }) {
  yield put({ type: UPDATE_FILE_MODEL_LOADING });
  message.loading({ key: 'update-file-model', content: 'Updating file model...' });
  try {
    const { data } = yield call(api, {
      method: 'PUT',
      url: apiConfig.fileModels.update_file_model(body),
      body: body.fileModel,
    });
    yield put({ type: UPDATE_FILE_MODEL_SUCCESS, data });
    message.success({ key: 'update-file-model', content: 'File model updated successfully!' });
    body.onSuccess && body.onSuccess();
  } catch (error) {
    errorhandling(error);
    yield put({ type: UPDATE_FILE_MODEL_ERROR, errorMessage: error.message });
    message.error({ key: 'update-file-model', content: 'Failed to update file model' });
  }
}

export default function* watchFileModelsSaga() {
  yield takeLatest(GET_FILE_MODELS_CONFIG, getFileModelsConfig);
  yield takeLatest(GET_FILE_MODELS, getFileModelsList);
  yield takeLatest(CREATE_FILE_MODEL, createFileModel);
  yield takeLatest(DELETE_FILE_MODEL, deleteFileModel);
  yield takeLatest(UPDATE_FILE_MODEL, updateFileModel);
}
