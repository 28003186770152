export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const GET_TRANSACTIONS_LOADING = 'GET_TRANSACTIONS_LOADING';
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS';
export const GET_TRANSACTIONS_ERROR = 'GET_TRANSACTIONS_ERROR';

export const GET_EXPORT_TRANSACTIONS = 'GET_EXPORT_TRANSACTIONS';
export const GET_EXPORT_TRANSACTIONS_LOADING = 'GET_EXPORT_TRANSACTIONS_LOADING';
export const GET_EXPORT_TRANSACTIONS_SUCCESS = 'GET_EXPORT_TRANSACTIONS_SUCCESS';
export const GET_EXPORT_TRANSACTIONS_ERROR = 'GET_EXPORT_TRANSACTIONS_ERROR';

export const GET_TRANSACTION_TYPES = 'GET_TRANSACTION_TYPES';
export const GET_TRANSACTION_TYPES_LOADING = 'GET_TRANSACTION_TYPES_LOADING';
export const GET_TRANSACTION_TYPES_SUCCESS = 'GET_TRANSACTION_TYPES_SUCCESS';
export const GET_TRANSACTION_TYPES_ERROR = 'GET_TRANSACTION_TYPES_ERROR';

export const getTransactions = (body, filters) => ({ type: GET_TRANSACTIONS, body, filters });

export const getExportTransactions = ({ employerId, totalCount }, filters, onSuccess) => ({
  type: GET_EXPORT_TRANSACTIONS,
  body: { employerId, totalCount },
  filters,
  onSuccess,
});

export const getTransactionTypes = (body) => ({
  type: GET_TRANSACTION_TYPES,
  body,
});
