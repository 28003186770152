import React from 'react';
import { App, Button, Card, Col, Divider, Row, Space, Typography } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import ColorInputField from './ColorInputField';
import { colorSchemeFields } from '../constants';

export default function ColorSchemeSection({ onReset }) {
  const { modal } = App.useApp();

  const onRestColorScheme = () => {
    modal.confirm({
      title: 'Reset Theme Color Scheme',
      content: (
        <>
          <Typography.Paragraph>
            <Typography.Text>
              This action will{' '}
              <Typography.Text type="danger" strong>
                reset your theme color scheme
              </Typography.Text>{' '}
              to the default settings and{' '}
              <Typography.Text type="danger" strong>
                cannot be undone
              </Typography.Text>
              .
            </Typography.Text>
          </Typography.Paragraph>
          <Typography.Paragraph style={{ marginTop: 16 }}>
            Are you sure you want to proceed?
          </Typography.Paragraph>
        </>
      ),
      footer: (_, { OkBtn, CancelBtn }) => (
        <Space size={'middle'}>
          <CancelBtn />
          <OkBtn />
        </Space>
      ),
      okText: 'Yes, Reset',
      cancelText: 'Cancel',
      okType: 'danger',
      onOk: () => onReset('colorScheme'),
    });
  };

  return (
    <Card
      title="Color Scheme"
      extra={
        <Button
          icon={<ReloadOutlined />}
          danger={true}
          onClick={() => {
            onRestColorScheme();
          }}>
          Reset Color Scheme
        </Button>
      }>
      <Row gutter={[16, 16]}>
        {colorSchemeFields.tokens.map(({ path, name, description }) => (
          <Col span={8} key={path.join()}>
            <ColorInputField path={path} label={name} description={description} />
          </Col>
        ))}
      </Row>

      <Divider orientation="left">
        <strong> Backgrounds</strong>
      </Divider>

      <Row gutter={[16, 16]}>
        {colorSchemeFields.backgrounds.map(({ path, name, description }) => (
          <Col span={8} key={path.join()}>
            <ColorInputField path={path} label={name} description={description} />
          </Col>
        ))}
      </Row>
    </Card>
  );
}
