import { combineReducers } from 'redux';
import { orderBy } from 'lodash';
import {
  CREATE_FILE_MODEL_ERROR,
  CREATE_FILE_MODEL_LOADING,
  CREATE_FILE_MODEL_SUCCESS,
  GET_FILE_MODELS_CONFIG_ERROR,
  GET_FILE_MODELS_CONFIG_LOADING,
  GET_FILE_MODELS_CONFIG_SUCCESS,
  GET_FILE_MODELS_ERROR,
  GET_FILE_MODELS_LOADING,
  GET_FILE_MODELS_SUCCESS,
  DELETE_FILE_MODEL_ERROR,
  DELETE_FILE_MODEL_LOADING,
  DELETE_FILE_MODEL_SUCCESS,
  UPDATE_FILE_MODEL_ERROR,
  UPDATE_FILE_MODEL_LOADING,
  UPDATE_FILE_MODEL_SUCCESS,
} from './actions';

const initialConfigStateObject = { loading: false, data: {}, error: '' };
const config = (state, action) => {
  if (typeof state === 'undefined') return initialConfigStateObject;

  switch (action.type) {
    case GET_FILE_MODELS_CONFIG_LOADING:
      return { ...state, loading: true, data: {}, error: '' };

    case GET_FILE_MODELS_CONFIG_SUCCESS:
      return { ...state, loading: false, data: action.data, error: '' };

    case GET_FILE_MODELS_CONFIG_ERROR:
      return { ...state, loading: false, data: {}, error: action.errorMessage };

    default:
      return state;
  }
};
const initialListStateObject = { loading: false, data: [], error: '', actionLoading: false };
const list = (state, action) => {
  if (typeof state === 'undefined') return initialListStateObject;

  switch (action.type) {
    case GET_FILE_MODELS_LOADING:
      return { ...state, loading: true, data: [], error: '' };

    case GET_FILE_MODELS_SUCCESS:
      return { ...state, loading: false, data: action.data.list, error: '' };

    case GET_FILE_MODELS_ERROR:
      return { ...state, loading: false, data: [], error: action.errorMessage };

    case CREATE_FILE_MODEL_LOADING:
    case DELETE_FILE_MODEL_LOADING:
    case UPDATE_FILE_MODEL_LOADING:
      return { ...state, actionLoading: true, error: '' };

    case CREATE_FILE_MODEL_ERROR:
    case DELETE_FILE_MODEL_ERROR:
    case UPDATE_FILE_MODEL_ERROR:
      return { ...state, actionLoading: false, error: action.errorMessage };

    case CREATE_FILE_MODEL_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        error: '',
        data: orderBy(
          [...state.data, action.data],
          ['fileType', 'name', 'createdAt'], // sort by these fields
          ['asc', 'asc', 'desc'] // in this order
        ),
      };

    case DELETE_FILE_MODEL_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        error: '',
        data: state.data.filter((item) => item.id !== action.data.id),
      };

    case UPDATE_FILE_MODEL_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        error: '',
        data: state.data.map((item) => (item.id === action.data.id ? action.data : item)),
      };

    default:
      return state;
  }
};

export default combineReducers({ config, list });
