import { differenceBy, keyBy } from 'lodash';
import { MobileAppScreens } from './constants';

const ALL_SCREEN_NAMES = MobileAppScreens.map(({ value }) => value);

function parseGetCategoriesData(data) {
  // Reduce the list of categories into an organized state object
  const state = data.list.reduce(
    (result, { screenName, order, id, ...category }) => {
      // Organize categories by id
      if (!result.byId[id]) result.byId[id] = { id, ...category, screens: [] };
      result.byId[id].screens.push({ name: screenName, order });

      // Organize categories by screenName
      if (!result.byScreenName[screenName]) result.byScreenName[screenName] = [];
      result.byScreenName[screenName].push({ id, order });

      return result;
    },
    { byId: {}, byScreenName: {} }
  );

  // fill in missing screenNames
  for (const screenName of ALL_SCREEN_NAMES) {
    if (!state.byScreenName[screenName]) state.byScreenName[screenName] = [];
  }
  return { ...state, total: data.total };
}

function parseAddCategoryData(stateData, createdCategory) {
  const updated = { ...stateData };
  const screens = createdCategory.screens.map(({ screenName, categoryOrder }) => ({
    name: screenName,
    order: categoryOrder,
  }));

  updated.byId[createdCategory.id] = { ...createdCategory, screens };
  screens.forEach(({ name, order }) => {
    if (!updated.byScreenName[name]) updated.byScreenName[name] = [];
    updated.byScreenName[name].push({ id: createdCategory.id, order });
  });
  updated.total += 1;
  return updated;
}

function parseUpdateCategoryData(stateData, updatedCategory) {
  const updatedState = { ...stateData };

  const screens = updatedCategory.screens.map(({ screenName, categoryOrder }) => ({
    name: screenName,
    order: categoryOrder,
  }));

  updatedState.byId[updatedCategory.id] = { ...updatedCategory, screens };

  // map for optimization
  const currentScreensMap = Object.fromEntries(screens.map(({ name, order }) => [name, order]));

  // update "byScreenName"
  updatedState.byScreenName = Object.entries(updatedState.byScreenName).reduce(
    (result, [screenName, categories]) => {
      const isCategoryInCurrentScreen = currentScreensMap[screenName] !== undefined;

      if (!isCategoryInCurrentScreen) {
        // remove category if it's no longer in screen
        result[screenName] = categories.filter(({ id }) => id !== updatedCategory.id);
      } else {
        if (!result[screenName]) result[screenName] = [];

        const categoryOrder = currentScreensMap[screenName];
        const existingCategoryIndex = categories.findIndex(({ id }) => id === updatedCategory.id);

        // already exists
        if (existingCategoryIndex !== -1) {
          // update order
          categories[existingCategoryIndex].order = categoryOrder;
          result[screenName] = categories;
        } else {
          // add new
          result[screenName] = [...categories, { id: updatedCategory.id, order: categoryOrder }];
        }
      }

      return result;
    },
    {}
  );

  return updatedState;
}

function parseDeleteCategoryData(stateData, deletedCategory) {
  const { byId, byScreenName } = { ...stateData };
  delete byId[deletedCategory.id];
  const newByScreenName = Object.entries(byScreenName).reduce((acc, [screenName, categories]) => {
    acc[screenName] = categories.filter(({ id }) => id !== deletedCategory.id);
    return acc;
  }, {});
  return { byId, byScreenName: newByScreenName };
}

function parseReorderCategoriesData(stateData, reorderedCategories) {
  const { byId, byScreenName } = { ...stateData };

  Object.entries(reorderedCategories).forEach(([screenName, categories]) => {
    byScreenName[screenName] = categories;

    Object.entries(byId).forEach(([id, category]) => {
      const categoryScreenIndex = category.screens.findIndex(({ name }) => name === screenName);
      if (categoryScreenIndex !== -1) {
        const newOrder = categories.find(({ id: categoryId }) => categoryId === id)?.order ?? -1;
        if (newOrder !== -1) {
          category.screens[categoryScreenIndex].order = newOrder;
          byId[id] = { ...category };
        }
      }
    });
  });

  return { byId, byScreenName };
}

export const CategoriesUtils = {
  parseGet: parseGetCategoriesData,
  parseAdd: parseAddCategoryData,
  parseUpdate: parseUpdateCategoryData,
  parseDelete: parseDeleteCategoryData,
  parseReorder: parseReorderCategoriesData,
};

function parseGetArticlesData(data) {
  const stateData = { byId: {}, byCategory: {}, uncategorized: [] };
  data.list.forEach(({ category, articles }) => {
    if (!category) {
      // handle uncategorized articles
      stateData.uncategorized.push(...articles.map(({ article }) => article.id));
      articles.forEach(({ article }) => {
        if (!stateData.byId[article.id])
          stateData.byId[article.id] = { ...article, categories: [] };
      });
    } else {
      articles.forEach(({ article, articleOrder }) => {
        if (!stateData.byId[article.id]) {
          stateData.byId[article.id] = { ...article, categories: [] };
        }
        stateData.byId[article.id].categories.push({ id: category.id, order: articleOrder });
        if (!stateData.byCategory[category.id]) stateData.byCategory[category.id] = [];
        stateData.byCategory[category.id].push({ article: article.id, order: articleOrder });
      });
    }
  });

  return stateData;
}

function parseAddArticleData(stateData, createdArticle) {
  const updated = { ...stateData };
  const categories = createdArticle.categories.map(({ category, articleOrder }) => ({
    id: category,
    order: articleOrder,
  }));
  updated.byId[createdArticle.id] = { ...createdArticle, categories };
  if (!categories.length) updated.uncategorized.push(createdArticle.id);
  else
    categories.forEach(({ id, order }) => {
      if (!updated.byCategory[id]) updated.byCategory[id] = [];
      updated.byCategory[id].push({ article: createdArticle.id, order });
    });
  return updated;
}

function parseUpdateArticleData(stateData, updatedArticle) {
  const updated = { ...stateData };

  // { byId, byCategory, uncategorized }
  const oldCategories = stateData.byId[updatedArticle.id]?.categories;
  const newCategories = updatedArticle.categories.map((item) => ({
    id: item.category,
    order: item.articleOrder,
  }));
  updated.byId[updatedArticle.id] = { ...updatedArticle, categories: newCategories };

  const addedCategories = differenceBy(newCategories, oldCategories, 'id');
  const removedCategories = differenceBy(oldCategories, newCategories, 'id');

  addedCategories.forEach(({ id, order }) => {
    if (!updated.byCategory[id]) updated.byCategory[id] = [];
    updated.byCategory[id].push({ article: updatedArticle.id, order });
  });
  removedCategories.forEach(({ id }) => {
    updated.byCategory[id] = updated.byCategory[id].filter(
      ({ article }) => article !== updatedArticle.id
    );
  });

  // if it was uncategorized
  if (!oldCategories?.length) {
    updated.uncategorized = updated.uncategorized.filter((id) => id !== updatedArticle.id);
  }
  return updated;
}

function parseDeleteArticleData(stateData, deletedArticle) {
  const { byId, byCategory, uncategorized } = { ...stateData };
  const { categories } = byId[deletedArticle.id];
  categories.forEach(({ id }) => {
    byCategory[id] = byCategory[id].filter(({ article }) => article !== deletedArticle.id);
  });
  if (!categories?.length) {
    uncategorized = uncategorized.filter((id) => id !== deletedArticle.id);
  }
  delete byId[deletedArticle.id];
  return { byId, byCategory, uncategorized };
}

function parseArtilceDeleteCategoryData(articlesStateData, deletedCategory) {
  const { byId, byCategory, uncategorized } = { ...articlesStateData };

  // Remove the category from all articles
  Object.values(byId).forEach((article) => {
    if (article.categories) {
      article.categories = article.categories.filter((cat) => cat.id !== deletedCategory.id);

      // If an article has no categories after deletion, add to uncategorized
      if (article.categories.length === 0 && !uncategorized.includes(article.id)) {
        uncategorized.push(article.id);
      }
    }
  });

  // Remove the category from byCategory
  delete byCategory[deletedCategory.id];

  return { byId, byCategory, uncategorized };
}

function parseReorderArticlesData(stateData, reorderedArticles) {
  const updated = { ...stateData };
  const { category, articles } = reorderedArticles;

  if (updated.byCategory[category]) {
    updated.byCategory[category] = articles.map(({ id, order }) => ({
      article: id,
      order,
    }));
  }

  articles.forEach(({ id, order }) => {
    if (updated.byId[id]) {
      const categoryIndex = updated.byId[id].categories.findIndex((cat) => cat.id === category);
      if (categoryIndex !== -1) {
        updated.byId[id].categories[categoryIndex].order = order;
      }
    }
  });

  return updated;
}

export const ArticlesUtils = {
  parseGet: parseGetArticlesData,
  parseAdd: parseAddArticleData,
  parseUpdate: parseUpdateArticleData,
  parseDelete: parseDeleteArticleData,
  parseDeleteCategory: parseArtilceDeleteCategoryData,
  parseReorder: parseReorderArticlesData,
};
