import React from 'react';
import { Layout, Row, Col, Dropdown, Button, Space } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  LogoutOutlined,
  SettingOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import '../../style.css';
import SelectWithSearch from '../SelectWithSearch';

const { Header } = Layout;

const LoggedInUserInfoDropDown = ({ handleMenuClick, userDetails, handleLogOut, employers }) => {
  const items = [
    {
      key: 'change-password',
      label: 'Change Password',
      icon: <SettingOutlined />,
    },
    { type: 'divider' },
    {
      key: 'logout',
      label: 'Logout',
      icon: <LogoutOutlined />,
    },
  ];
  let username = userDetails?.email?.split('@')[0];
  const employerId = userDetails['cognito:employerId'];
  const employer = employers?.find((item) => item?.id === employerId);

  const onClick = (event) => {
    if (event.key === 'logout') handleLogOut();
    else handleMenuClick(event);
  };

  return (
    <Dropdown menu={{ items, onClick }} trigger={['click']} placement="bottom" arrow>
      <Space>
        {employer ? employer?.contactPerson?.name : username} <UserOutlined size={20} />
      </Space>
    </Dropdown>
  );
};

const CustomHeader = (props) => {
  const {
    isSidebarCollapsed,
    toggleSidebar,
    employers,
    selectedEmployer,
    setSelectedEmployer,
    isEmployerDropdownVisible,
    handleMenuClick,
    userDetails,
    handleLogOut,
    path,
  } = props;

  const disabledPaths = ['/', '/superadmin-dashboard', '/employer-details/new'];

  function isPathDisabled(path) {
    // Check if the path is in the disabledPaths array or if it matches the pattern '/employer-details/{dynamicID}'
    return disabledPaths.includes(path) || /^\/employer-details\/[a-f\d]{24}$/.test(path);
  }

  const onSelectEmployer = (value) => {
    if (value) setSelectedEmployer(employers.list.find((item) => item.id === value));
    else setSelectedEmployer({ companyName: 'All Employers', id: null });
  };

  return (
    <Header className="container">
      <Row className="trigger">
        <Col span={1}>
          <Button
            type="text"
            icon={isSidebarCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => toggleSidebar(!isSidebarCollapsed)}
            style={{ fontSize: '16px', width: 64, height: 64 }}
          />
        </Col>
        <Col span={isEmployerDropdownVisible ? 16 : 20}></Col>
        {isEmployerDropdownVisible ? (
          <Col span={4}>
            <SelectWithSearch
              filterFields={['label', 'value']}
              placeholder="Choose Employer"
              value={selectedEmployer.value}
              onChange={onSelectEmployer}
              options={
                employers?.list?.map((item) => ({
                  label: item?.companyDetails?.name,
                  value: item.id,
                })) || []
              }
              disabled={isPathDisabled(path)}
              style={{ width: '100%' }}
              notFoundContent={'No Employers Found'}
            />
          </Col>
        ) : null}
        <Col span={2} style={{ textAlign: 'center' }}>
          <LoggedInUserInfoDropDown
            handleMenuClick={handleMenuClick}
            userDetails={userDetails}
            handleLogOut={handleLogOut}
            employers={employers?.list || []}
          />
        </Col>
      </Row>
    </Header>
  );
};

export default CustomHeader;
