import React from 'react';
import { Button, Dropdown, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { HiOutlineDocumentPlus, HiOutlineSquaresPlus } from 'react-icons/hi2';
import { useArticlesContext } from '../containers/ArticlesContext';
import { DRAWER_ACTIONS } from '../constants';

const items = [
  {
    key: DRAWER_ACTIONS.NEW_CATEGORY,
    label: <Typography.Text>New Category</Typography.Text>,
    icon: <HiOutlineSquaresPlus />,
  },
  {
    key: DRAWER_ACTIONS.NEW_ARTICLE,
    label: <Typography.Text>New Article</Typography.Text>,
    icon: <HiOutlineDocumentPlus />,
  },
];

export default function AddNewDropDown() {
  const { openDrawer } = useArticlesContext();

  const onClick = ({ key }) => openDrawer.set({ key, data: null });

  return (
    <Dropdown menu={{ items, onClick }} placement="bottomRight">
      <Button
        type={'primary'}
        color={'primary'}
        icon={<PlusOutlined />}
        size={'middle'}
        style={{ minWidth: '10rem' }}
        iconPosition={'start'}>
        New
      </Button>
    </Dropdown>
  );
}
