import { FaRegMoneyBill1 } from 'react-icons/fa6';
import { BiBody, BiBrain, BiBriefcase, BiGlobe, BiRestaurant } from 'react-icons/bi';
import { FaRegBuilding } from 'react-icons/fa';
import { LiaPiggyBankSolid } from 'react-icons/lia';
import { RiAlarmWarningLine } from 'react-icons/ri';
import { AiOutlineAreaChart } from 'react-icons/ai';

export const MobileAppScreens = [
  { label: 'Home', value: 'HOME' },
  { label: 'Well Being', value: 'WELLBEING' },
];

export const CategoryIconsList = [
  {
    name: 'Finance',
    value: 'financial_icon',
    icon: FaRegMoneyBill1,
  },
  {
    name: 'Health & Wellness',
    value: 'healthy_living_icon',
    icon: BiBody,
  },
  {
    name: 'Mental Health',
    value: 'mental_health_icon',
    icon: BiBrain,
  },
  {
    name: 'Career',
    value: 'career_growth_icon',
    icon: BiBriefcase,
  },
  {
    name: 'Work-Life',
    value: 'work_life_balance_icon',
    icon: BiGlobe,
  },
  {
    name: 'Emergency',
    value: 'emergency_planning_icon',
    icon: RiAlarmWarningLine,
  },
  {
    name: 'Nutrition',
    value: 'nutrition_icon',
    icon: BiRestaurant,
  },
  {
    name: 'Savings',
    value: 'saving_future_icon',
    icon: LiaPiggyBankSolid,
  },
  {
    name: 'Money Management',
    value: 'money_management_icon',
    icon: AiOutlineAreaChart,
  },
  {
    name: 'Community',
    value: 'community_resources_icon',
    icon: FaRegBuilding,
  },
];

export const DRAWER_ACTIONS = {
  NEW_CATEGORY: 'new-category',
  EDIT_CATEGORY: 'edit-category',
  NEW_ARTICLE: 'new-article',
  EDIT_ARTICLE: 'edit-article',
};

export const REORDERING_ACTIONS = {
  REORDER_CATEGORIES: 'reorder-categories',
  REORDER_ARTICLES: 'reorder-articles',
};

export const MAX_ARTICLE_IMAGE_FILE_SIZE = 3 * 1024 * 1024; // 3MB
