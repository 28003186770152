import React from 'react';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { CustomSpinner } from '../../../shared';
import {
  AddArticleDrawer,
  AddCategoryDrawer,
  CategoriesList,
  ReorderArticles,
  ReorderCategories,
} from './index';
import ArticlesSection from './ArticlesSection';
import { useArticlesContext } from '../containers/ArticlesContext';
import { REORDERING_ACTIONS } from '../constants';

export default function Articles() {
  const { reordering } = useArticlesContext();
  const { loading } = useSelector((state) => state.library.categories);

  if (loading) return <CustomSpinner loading={loading} />;

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        {!reordering.value.key && <CategoriesList />}
        {reordering.value.key === REORDERING_ACTIONS.REORDER_CATEGORIES && <ReorderCategories />}
        {reordering.value.key === REORDERING_ACTIONS.REORDER_ARTICLES && <ReorderArticles />}
      </Col>
      <Col span={24}>
        <ArticlesSection />
      </Col>
      <AddCategoryDrawer />
      <AddArticleDrawer />
    </Row>
  );
}
