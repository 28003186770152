import React from 'react';
import { DragOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useArticlesContext } from '../containers/ArticlesContext';

export default function ReorderButton({ label, value, data = null, isDisabled = false }) {
  const { reordering } = useArticlesContext();
  return (
    <Button
      type={'default'}
      color={'primary'}
      icon={<DragOutlined />}
      disabled={isDisabled}
      size={'middle'}
      style={{ minWidth: '10rem' }}
      onClick={() => reordering.set({ key: value, data })}
      iconPosition={'start'}>
      {label}
    </Button>
  );
}
