import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_CREDENTIALS,
  GET_CREDENTIALS_LOADING,
  // GET_CREDENTIALS_SUCCESS,
  GET_CREDENTIALS_ERROR,
  GET_FILE_PROCESS_STATUS,
  GET_FILE_PROCESS_STATUS_LOADING,
  GET_FILE_PROCESS_STATUS_SUCCESS,
  GET_FILE_PROCESS_STATUS_ERROR,
  UPLOAD_FILE_TO_S3,
  UPLOAD_FILE_TO_S3_LOADING,
  UPLOAD_FILE_TO_S3_SUCCESS,
  UPLOAD_FILE_TO_S3_ERROR,
  UPLOAD_PAYROLL_FILE_LOADING,
  UPLOAD_PAYROLL_FILE_SUCCESS,
  UPLOAD_PAYROLL_FILE_ERROR,
  UPLOAD_PAYROLL_FILE,
} from './actions';
import { api } from '../../utils/api';
import { errorhandling } from '../../utils/helper';
import { apiConfig } from '../../utils/apiConfig';

function* getCredentials({ body }) {
  yield put({ type: GET_CREDENTIALS_LOADING });
  try {
    // const { data } = yield call(api, {
    //     method: 'POST',
    //     url: baseURL + 'auth/getCredentials',
    //     body,
    //     serverless: true,
    // });
    // yield put({ type: GET_CREDENTIALS_SUCCESS, data: data });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_CREDENTIALS_ERROR, errorMessage: '' });
  }
}

function* getPayrollDataHistory({ body }) {
  yield put({ type: GET_FILE_PROCESS_STATUS_LOADING });
  try {
    console.log({ body });
    const query = {
      employerId: body.employerId,
      payroll_data_type: body.fileType,
      item_per_page: 10,
      number_page: body.page,
    };
    Object.keys(query).forEach((key) => query[key] === '' && delete query[key]);
    const { data } = yield call(api, {
      method: 'GET',
      url: apiConfig.payroll.get_data({
        query,
      }),
      // body
    });

    // reorder data by created_at
    yield put({ type: GET_FILE_PROCESS_STATUS_SUCCESS, data: data });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_FILE_PROCESS_STATUS_ERROR, errorMessage: '' });
  }
}

function* getTransactionTypes({ body }) {
  yield put({ type: UPLOAD_FILE_TO_S3_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'GET',
      url: 'salaryadvance/salary-manager/transactionType',
      body,
    });
    yield put({ type: UPLOAD_FILE_TO_S3_SUCCESS, data: data });
  } catch (error) {
    errorhandling(error);
    yield put({ type: UPLOAD_FILE_TO_S3_ERROR, errorMessage: '' });
  }
}

function* uploadPayrollFile({ body }) {
  yield put({ type: UPLOAD_PAYROLL_FILE_LOADING });
  try {
    const query = {
      employer_id: body.employer_id,
      payroll_data_type: body.payroll_data_type,
    };
    if (body.pay_period_id) query.pay_period_id = body.pay_period_id;

    const { data } = yield call(api, {
      method: 'POST',
      url: apiConfig.payroll.upload_data(query),
      body: body.formData,
    });
    body.onSuccess && body.onSuccess(data);
    yield put({ type: UPLOAD_PAYROLL_FILE_SUCCESS, data: data });
  } catch (error) {
    errorhandling(error);
    body.onError && body.onError(error);
    yield put({ type: UPLOAD_PAYROLL_FILE_ERROR, errorMessage: '' });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchPayrollDataSaga() {
  yield takeLatest(GET_CREDENTIALS, getCredentials);
  yield takeLatest(GET_FILE_PROCESS_STATUS, getPayrollDataHistory);
  yield takeLatest(UPLOAD_FILE_TO_S3, getTransactionTypes);
  yield takeLatest(UPLOAD_PAYROLL_FILE, uploadPayrollFile);
}
