import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { usePreviewContext } from '../context';
import Fuse from 'fuse.js';
import { Col, Select, Table, Typography } from 'antd';
import { fuseOptions, VALIDATION_BATCH_SIZE } from '../constants';
import { mapColumns, transformColumnValue, validateBatch, validateRequiredColumns } from '../utils';
import StepWrapper from './StepWrapper';
import StepControls from './StepControls';

const { Text } = Typography;

export default function ReviewStep() {
  const { rawData, columnMapping, fileColumns, step, fileModel, errors } = usePreviewContext();
  const [isValidating, setIsValidating] = useState(false);
  const getFuse = useCallback((list, distance) => new Fuse(list, fuseOptions(distance)), []);

  useEffect(() => {
    if (!Object.keys(columnMapping.value).length) {
      // prevent reset when changing the step
      const newColumnMapping = mapColumns(fileModel.columnsConfig.list, fileColumns.list, getFuse);
      columnMapping.set(newColumnMapping);
      validateMapping(newColumnMapping);
    } else {
      validateMapping(columnMapping.value);
    }
  }, []);

  const onSelectMapping = (target) => (source) => {
    columnMapping.set((prev) => {
      const updated = { ...prev, [target]: source };
      validateMapping(updated);
      return updated;
    });
  };

  const mappingData = useMemo(() => {
    return fileModel.columnsConfig.list.map((item) => {
      const sourceColumn = columnMapping.value[item.targetField];
      const samples = sourceColumn
        ? rawData.list
            .slice(0, 3)
            .map((row) => transformColumnValue(item, row[sourceColumn]))
            .filter(Boolean)
        : [];

      return { key: item._id, column: item, sourceColumn, samples: samples };
    });
  }, [columnMapping.value, fileModel.columnsConfig.list]);

  const validateMapping = async (columnMapping) => {
    setIsValidating(true);
    try {
      validateRequiredColumns(fileModel.columnsConfig, columnMapping);

      // Validate the data
      const items = Object.entries(fileModel.columnsConfig.byTargetField);
      for (const [targetField, columnConfig] of items) {
        // Iterate over the columns
        const sourceColumn = columnMapping[targetField];
        if (!sourceColumn) continue;

        const totalRows = rawData.list.length;
        for (let start = 0; start < totalRows; start += VALIDATION_BATCH_SIZE) {
          // Process rows in batches
          const end = Math.min(start + VALIDATION_BATCH_SIZE, totalRows); // Ensure we don't exceed the array length
          const batch = rawData.list.slice(start, end);

          validateBatch(batch, start, columnConfig, sourceColumn);
        }
      }

      errors.reset();
      return true;
    } catch (error) {
      errors.set([error]);
    } finally {
      setIsValidating(false);
    }
  };

  return (
    <>
      <StepWrapper>
        <Text type="secondary" style={{ marginBottom: '1rem' }}>
          Please review the column mapping and make any necessary adjustments before proceeding.
        </Text>

        <ColumnMappingTable
          mappingData={mappingData}
          onSelectMapping={onSelectMapping}
          fileColumnsList={fileColumns.list}
        />
      </StepWrapper>
      <StepControls
        current={step.currentIndex}
        hasNext={!isValidating && errors.list.length === 0}
        isFinal={false}
        onPrev={step.goPrev}
        onNext={step.goNext}
      />
    </>
  );
}

const ColumnMappingTable = ({ onSelectMapping, fileColumnsList, mappingData }) => {
  const selectColumnOptions = useMemo(
    () => fileColumnsList.map((item) => ({ label: item, value: item })),
    [fileColumnsList]
  );

  const DestinationColumn = memo(({ field, record }) => (
    <Typography>
      <Text strong>{field.inputColumn}</Text>
      {field.isRequired && <Text italic> (required)</Text>}
    </Typography>
  ));

  const DataSampleColumn = memo(({ field }) => (
    <Text code>{field?.length ? field.join(', ') : '-'}</Text>
  ));

  const SelectSourceColumn = memo(({ field, record }) => (
    <Select
      options={selectColumnOptions}
      onChange={onSelectMapping(record.column.targetField)}
      defaultValue={field}
      style={{ width: '100%' }}
      allowClear
    />
  ));

  const columns = [
    {
      title: 'Destination Column',
      dataIndex: 'column',
      align: 'center',
      render: (field, record) => <DestinationColumn field={field} record={record} />,
    },
    {
      title: 'Your File Column',
      dataIndex: 'sourceColumn',
      align: 'center',
      render: (field, record) => <SelectSourceColumn field={field} record={record} />,
    },
    {
      title: 'Data Sample',
      dataIndex: 'samples',
      align: 'center',
      width: '30%',
      render: (field) => <DataSampleColumn field={field} />,
    },
  ];

  return (
    <Col span={24}>
      <Table dataSource={mappingData} columns={columns} size="small" pagination={false} />
    </Col>
  );
};
