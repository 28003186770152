import { Row, theme } from 'antd';
import React from 'react';

function StepWrapper({ children }) {
  const { token } = theme.useToken();
  const contentStyle = {
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
  };
  return (
    <Row
      style={contentStyle}
      gutter={[16, 16]}
      align={'middle'}
      justify={'center'}
      children={children}
    />
  );
}

export default StepWrapper;
