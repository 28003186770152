import { CheckOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Flex } from 'antd';
import React from 'react';

export default function StepControls({
  current,
  onPrev,
  onNext,
  hasNext = false,
  loadingNext = false,
  isFinal = false,
}) {
  return (
    <Flex
      flex={1}
      justify="flex-end"
      gap={'.5rem'}
      align="end"
      style={{ marginInlineEnd: '2rem', marginBlock: '1rem' }}>
      {current > 0 && (
        <Button
          onClick={onPrev}
          icon={<LeftOutlined />}
          children="Previous"
          style={{ width: '10rem' }}
        />
      )}
      {isFinal ? (
        <Button
          type="primary"
          style={{ width: '12rem' }}
          onClick={onNext}
          disabled={!hasNext}
          loading={loadingNext}
          icon={<CheckOutlined />}
          children="Upload"
        />
      ) : (
        <Button
          type="primary"
          onClick={onNext}
          icon={<RightOutlined />}
          iconPosition="end"
          children="Next"
          loading={loadingNext}
          style={{ width: '10rem' }}
          disabled={!hasNext}
        />
      )}
    </Flex>
  );
}
