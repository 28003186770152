import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BreadcrumbList, EmployerSelectionPrompt } from '../../../shared';
import { getFileModels, getFileModelsConfig } from '../actions';
import { FileModelsComponent } from '../components';
import Paths from '../../../utils/paths';

const breadcrumbListItems = [
  { href: Paths.Settings(), title: 'Settings' },
  { title: 'File Models' },
];

function FileModels() {
  const dispatch = useDispatch();
  const selectedEmployer = useSelector((state) => state.shared.selectedEmployer);

  useEffect(() => {
    // load file models config
    dispatch(getFileModelsConfig());
  }, []);

  useEffect(() => {
    // load file models list
    if (selectedEmployer?.value) dispatch(getFileModels({ employerId: selectedEmployer.value }));
  }, [selectedEmployer?.value]);

  return (
    <>
      <BreadcrumbList items={breadcrumbListItems} />
      {!selectedEmployer?.value ? (
        <EmployerSelectionPrompt />
      ) : (
        <FileModelsComponent employerId={selectedEmployer.value} />
      )}
    </>
  );
}

export default FileModels;
