import React from 'react';

const initialState = {
  selectedScreen: 'ALL',
  openDrawer: { key: null, data: null },
  reordering: { key: null, data: null },
};

const ArticlesContext = React.createContext({
  selectedScreen: {
    value: initialState.selectedScreen,
    set: () => {},
  },
  openDrawer: {
    value: { key: null, data: null },
    set: () => {},
    reset: () => {},
  },
  reordering: {
    value: { key: null, data: null },
    set: () => {},
    reset: () => {},
  },
});

// hook for simple usage
const useArticlesContext = () => React.useContext(ArticlesContext);

function ArticlesProvider({ children }) {
  const [selectedScreen, setSelectedScreen] = React.useState(initialState.selectedScreen);
  const [openDrawer, setOpenDrawer] = React.useState(initialState.openDrawer);
  const [reordering, setReordering] = React.useState(initialState.reordering);

  return (
    <ArticlesContext.Provider
      value={{
        selectedScreen: { value: selectedScreen, set: setSelectedScreen },
        openDrawer: {
          value: openDrawer,
          set: setOpenDrawer,
          reset: () => setOpenDrawer(initialState.openDrawer),
        },
        reordering: {
          value: reordering,
          set: setReordering,
          reset: () => setReordering(initialState.reordering),
        },
      }}
      children={children}
    />
  );
}

export { useArticlesContext, ArticlesProvider, ArticlesContext };
