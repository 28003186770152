// Reminders
export const GET_REMINDERS = 'GET_REMINDERS';
export const GET_REMINDERS_LOADING = 'GET_REMINDERS_LOADING';
export const GET_REMINDERS_SUCCESS = 'GET_REMINDERS_SUCCESS';
export const GET_REMINDERS_ERROR = 'GET_REMINDERS_ERROR';

export const UPDATE_REMINDER = 'UPDATE_REMINDER';
export const UPDATE_REMINDER_LOADING = 'UPDATE_REMINDER_LOADING';
export const UPDATE_REMINDER_SUCCESS = 'UPDATE_REMINDER_SUCCESS';
export const UPDATE_REMINDER_ERROR = 'UPDATE_REMINDER_ERROR';

export const CREATE_REMINDER = 'CREATE_CREATE_REMINDER';
export const CREATE_CREATE_REMINDER_LOADING = 'CREATE_CREATE_REMINDER_LOADING';
export const CREATE_CREATE_REMINDER_SUCCESS = 'CREATE_CREATE_REMINDER_SUCCESS';
export const CREATE_CREATE_REMINDER_ERROR = 'CREATE_CREATE_REMINDER_ERROR';

export const RESET_REMINDER = 'RESET_REMINDER';

export const getReminders = (body) => ({ type: GET_REMINDERS, body });
export const updateReminder = (body) => ({ type: UPDATE_REMINDER, body });
export const createReminder = (body) => ({ type: CREATE_REMINDER, body });

export const resetReminder = (body) => ({ type: RESET_REMINDER, body });
