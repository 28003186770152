import React, { useEffect } from 'react';
import { Layout, Form, Row, Col } from 'antd';
import { useDispatch } from 'react-redux';
import { CustomButton, CustomInput, CustomDropdown } from '../../../shared';
import BreadcrumbList from '../../../shared/components/Breadcrumb';
import { getReminders, createReminder, updateReminder, resetReminder } from '../actions';
import { breadcrumbListItems } from '../constants';

const REMINDER_TYPES = [{ label: 'Employee Invitation', value: 'employee-invitation' }];

const DURATION_UNITS = [
  { label: 'Days', value: 'days' },
  { label: 'Months', value: 'months' },
  { label: 'Years', value: 'years' },
];
const ReminderComponent = ({ selectedEmployer, reminders, loading, error }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const isUpdate = reminders && reminders.id;
  const isSubmitting = loading && (form.isFieldsTouched() || isUpdate);

  useEffect(() => {
    if (selectedEmployer?.value) {
      dispatch(getReminders(selectedEmployer.value));
    }
    return () => {
      dispatch(resetReminder());
    };
  }, [dispatch, selectedEmployer?.value]);

  useEffect(() => {
    if (isUpdate && reminders) {
      form.setFieldsValue({
        reminderType: reminders.reminderType,
        frequencyUnit: reminders.frequencyUnit,
        frequency: reminders.frequency,
      });
    } else {
      form.resetFields();
    }
  }, [form, isUpdate, reminders]);

  if (!selectedEmployer?.value) {
    return (
      <Layout>
        <BreadcrumbList items={breadcrumbListItems} />
      </Layout>
    );
  }

  const handleSubmit = (values) => {
    const formattedValues = {
      ...values,
      employer: selectedEmployer.value,
    };

    if (isUpdate) {
      dispatch(updateReminder({ reminder_id: reminders.id, reminder: formattedValues }));
    } else {
      dispatch(createReminder(formattedValues));
    }
  };

  return (
    <Layout>
      <BreadcrumbList items={breadcrumbListItems} />
      <div className="bg-white p-6 rounded-lg shadow">
        {error && <div className="mb-4 text-red-500">Error: {error}</div>}
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <h2 className="text-xl font-semibold mb-4">
              {isUpdate ? 'Update Reminder' : 'Create New Reminder'}
            </h2>
          </Col>

          <Col span={24}>
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
              <Row gutter={[24, 24]}>
                <Col span={12}>
                  <Form.Item
                    label="Reminder Type"
                    name="reminderType"
                    rules={[{ required: true, message: 'Please select reminder type' }]}>
                    <CustomDropdown options={REMINDER_TYPES} placeholder="Select reminder type" />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Frequency Unit"
                    name="frequencyUnit"
                    rules={[{ required: true, message: 'Please select duration unit' }]}>
                    <CustomDropdown options={DURATION_UNITS} placeholder="Select duration unit" />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Frequency"
                    name="frequency"
                    rules={[{ required: true, message: 'Please enter duration value' }]}>
                    <CustomInput type="number" placeholder="Enter duration value" />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item>
                    <CustomButton
                      loading={isSubmitting}
                      type="primary"
                      label={isUpdate ? 'Update Reminder' : 'Create Reminder'}
                      htmlType="submit"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default ReminderComponent;
